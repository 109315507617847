<sub-header></sub-header>

<mat-card class="about-card mat-elevation-z4">
  <mat-card-header> <h2>About</h2> </mat-card-header>
  <mat-card-content>
    <table>
      <tr>
        <td>Service Cloud server version:</td>
        <td>{{ ascVersion }}</td>
      </tr>
      <tr>
        <td>Service Cloud UI version:</td>
        <td>{{ frontendVersion }}</td>
      </tr>
    </table>
  </mat-card-content>
</mat-card>
