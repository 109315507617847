import { Component, HostListener, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';
import { ErrorHistoryService } from './error-handling/services/error-history.service';
import { ToastsService } from './error-handling/services/toasts.service';
import { AppStorageService } from './shared/app-storage.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnDestroy {
  @HostListener('window:beforeunload', ['$event'])
  beforeunload() {
    this.messageHistory.saveToastsCards();
  }
  title = 'alcon-frontend';
  showHeader = true;
  showFooter = true;
  constructor(private store: AppStorageService, private router: Router, public messageHistory: ErrorHistoryService, private toast: ToastsService) {
    this.router.events.pipe(filter((evt) => evt instanceof NavigationStart)).subscribe((resp) => {
      const route = resp as NavigationStart;
      if (route.url.includes('/login')) {
        this.showHeader = false;
        this.showFooter = false;
        return;
      }
      this.showHeader = true;
      this.showFooter = true;
    });

    this.initStore();
    this.messageHistory.loadToastsCards();
  }
  ngOnDestroy(): void {
    this.messageHistory.saveToastsCards();
  }

  initStore() {
    if (this.store.storeInitialized()) return;

    this.store.register('__toast_cards__', 'array');

    // device list
    this.store.register('devicelist_filter_ids', 'array');
    this.store.register('devicelist_filter_serialnumbers', 'array');
    this.store.register('devicelist_filter_devicetypes', 'array');
    this.store.register('devicelist_filter_status', 'array');
    this.store.register('devicelist_sort_column', 'string');
    this.store.register('devicelist_sort_direction', 'string');
    this.store.register('devicelist_pagination_pagesize', 'number');

    // device details
    this.store.register('device_downloadablecontent_sort_column', 'string');
    this.store.register('device_downloadablecontent_sort_direction', 'string');
    this.store.register('device_downloadablecontent_pagination_pagesize', 'number');

    this.store.register('device_connectionlog_sort_column', 'string');
    this.store.register('device_connectionlog_sort_direction', 'string');
    this.store.register('device_connectionlog_pagination_pagesize', 'number');

    this.store.register('device_filestorage_sort_column', 'string');
    this.store.register('device_filestorage_sort_direction', 'string');
    this.store.register('device_filestorage_pagination_pagesize', 'number');

    this.store.register('device_fileuploadlog_sort_column', 'string');
    this.store.register('device_fileuploadlog_sort_direction', 'string');
    this.store.register('device_fileuploadlog_pagination_pagesize', 'number');

    this.store.setStoreInitializedDone();
  }
}
