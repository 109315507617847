import { Pipe, PipeTransform } from '@angular/core';
import { filesize } from 'filesize';

@Pipe({
    name: 'filesize',
    standalone: false
})
export class FileSizePipe implements PipeTransform {
  private static transformOne(value: number, options?: any): string {
    return String(filesize(value, options));
  }

  transform(value: number | number[], options?: any): string | string[] {
    if (Array.isArray(value)) {
      return value.map((val) => FileSizePipe.transformOne(val, options));
    }

    return FileSizePipe.transformOne(value, options);
  }
}
