import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DetailsSubs<T> {
  table: {
    type: 'table';
    title?: string;
    keyColumnLabel: string;
    keyColumnId: string;
    valueColumnId: string;
    valueColumnLabel: string;
    data: {
      value: string;
      label: string;
    }[];
  };
  text: {
    type: 'text';
    title?: string;
    text: string;
  };
}

type DetailsDialogSubcomponent<T> = DetailsSubs<T>[DetailsDialogSubcomponentKey<T>];

type DetailsDialogSubcomponentKey<T> = keyof DetailsSubs<T>;

export type DetailsDialogData<T> = {
  dialogTitle?: string;
  components: DetailsDialogSubcomponent<T>[];
  title?: string;
};

@Component({
    selector: 'app-details-dialog',
    templateUrl: './details-dialog.component.html',
    styleUrls: ['./details-dialog.component.scss'],
    standalone: false
})
export class DetailsDialogComponent<Details = unknown> {
  dialogData: DetailsDialogData<Details>;
  constructor(@Inject(MAT_DIALOG_DATA) dialogData: DetailsDialogData<Details>) {
    this.dialogData = dialogData;
  }
}
