<sub-header label="Ongoing Uploads"> </sub-header>

<app-asc-configurable-table
  class="ongoing-uploads-table"
  [dataSource]="dataSource"
  [columns]="columns"
  (sortChange)="sortChange($event)"
  (paginatorChange)="paginatorListener($event)"
  [pageables]="pageables"
  [sortProps]="sort"
  [columnCells]="[deviceIdTemp, customerTemp, fileNameTemp, startTimeTemp, totalSizeTemp, currentSizeTemp, progressTemp]"
>
</app-asc-configurable-table>

<ng-template #deviceIdTemp let-tableElem="tableElem">
  <div [appClipboardCopy]="tableElem.deviceId">{{ tableElem.deviceId }}</div>
</ng-template>

<ng-template #customerTemp let-tableElem="tableElem">
  <div>{{ tableElem.customer }}</div>
</ng-template>

<ng-template #fileNameTemp let-tableElem="tableElem">
  <div>{{ tableElem.fileName.substring(tableElem.fileName.lastIndexOf('/') + 1) }}</div>
</ng-template>

<ng-template #startTimeTemp let-tableElem="tableElem">
  <div>{{ tableElem.uploadStart | date : 'yyyy-MM-dd, HH:mm' }}</div>
</ng-template>

<ng-template #totalSizeTemp let-tableElem="tableElem">
  <div>{{ tableElem.totalFileSize | formatFileSize }}</div>
</ng-template>

<ng-template #currentSizeTemp let-tableElem="tableElem">
  <div>{{ tableElem.currentUploadSize | formatFileSize }}</div>
</ng-template>

<ng-template #progressTemp let-tableElem="tableElem">
  <mat-progress-bar
    mode="determinate"
    [value]="calculatePercentage(tableElem.totalFileSize, tableElem.currentUploadSize)"
    [appAscTooltip]="calculatePercentage(tableElem.totalFileSize, tableElem.currentUploadSize) + ' %'"
  ></mat-progress-bar>
</ng-template>
