@if (showHeader) {
<app-header></app-header>
}
<mat-drawer-container id="content-wrapper" autosize>
  <mat-drawer [opened]="messageHistory.opened$ | async" mode="side" position="end">
    <app-error-history></app-error-history>
  </mat-drawer>
  <router-outlet></router-outlet>
</mat-drawer-container>

<app-footer></app-footer>
