import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, catchError, of, shareReplay } from 'rxjs';
import { ToastsService } from 'src/app/error-handling/services/toasts.service';
import { AscTabbedPageComponent } from 'src/app/shared/components/asc-tabbed-page/asc-tabbed-page.component';
import { Device } from '../../interfaces/Device';
import { DeviceApiService } from '../../services/device-api.service';
import { DeviceDetailsService } from '../../services/device-details.service';

@Component({
    selector: 'app-device-details',
    templateUrl: './device-details.component.html',
    styleUrls: ['./device-details.component.scss'],
    standalone: false
})
export class DeviceDetailsComponent extends AscTabbedPageComponent implements OnDestroy {
  device$: Observable<Device>;
  paramSub$ = new Subscription();
  constructor(
    activeRoute: ActivatedRoute,
    router: Router,
    private deviceApi: DeviceApiService,
    private deviceDetailsService: DeviceDetailsService,
    private toasts: ToastsService
  ) {
    super(activeRoute, router);
    this.deviceDetailsService.reloadDevice$.subscribe((resp) => (resp === 'RELOAD' ? this.loadDevice() : null));

    this.paramSub$ = this.activeRoute.paramMap.subscribe((params) => {
      this.deviceId = params.get('deviceId');
      this.loadDevice();
    });
  }
  deviceId: string | null;

  toDeviceList() {
    this.router.navigate(['devicelist']);
  }

  loadDevice() {
    if (this.deviceId === null) {
      return;
    }
    this.device$ = this.deviceApi.getDevice(this.deviceId, 'v2').pipe(
      shareReplay(),
      catchError((err) => {
        this.toasts.raise({ message: 'Could not get device with ID ' + this.deviceId, title: 'Get Device', error: err }, 'ERROR');
        return of();
      })
    );
  }

  ngOnDestroy(): void {
    this.paramSub$.unsubscribe();
  }
}
