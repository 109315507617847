import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, of } from 'rxjs';
import { ToastsService } from 'src/app/error-handling/services/toasts.service';
import { AscConfirmDialogAction, AscConfirmDialogData } from '../../interfaces/AscConfirmDialogData';
import { DialogResponse } from '../../interfaces/DialogResponse';

@Component({
    selector: 'app-asc-confirm-dialog',
    templateUrl: './asc-confirm-dialog.component.html',
    styleUrls: ['./asc-confirm-dialog.component.scss'],
    standalone: false
})
export class AscConfirmDialogComponent<T, R> {
  inProgress = false;

  data: AscConfirmDialogData<T, R>;
  constructor(
    private dialogRef: MatDialogRef<AscConfirmDialogComponent<T, R>, Partial<DialogResponse>>,
    @Inject(MAT_DIALOG_DATA) dialogData: AscConfirmDialogData<T, R>,
    private toasts: ToastsService
  ) {
    this.data = dialogData;
  }

  actionButtonClicked() {
    this.inProgress = true;
    if (this.data.action === 'parent-action') {
      this.dialogRef.close({ status: 'ACTION' });
      return;
    }
    const action = this.data.action as AscConfirmDialogAction<R>;
    this.data.action
      .call()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          const message = action.errorHandler(err);
          this.toasts.raise({ title: this.data.title, message: message, error: err }, 'ERROR');
          this.dialogRef.close({ status: 'ERROR' });
          return of();
        })
      )
      .subscribe((resp: R) => {
        const message = action.successHandler(resp);
        this.toasts.raise({ title: this.data.title, message: message }, 'SUCCESS', false);
        this.dialogRef.close({ status: 'SUCCESS' });
      });
  }
  closeButtonClicked() {
    this.dialogRef.close({ status: 'CANCEL' });
  }
}
