import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject, Observable, Subscription, of, switchMap } from 'rxjs';
import { Device } from 'src/app/device/interfaces/Device';
import { ToastsService } from 'src/app/error-handling/services/toasts.service';
import { AppStorageService } from 'src/app/shared/app-storage.service';
import { AscConfigurableTableComponent, ConfigPageEvent } from 'src/app/shared/components/asc-configurable-table/asc-configurable-table.component';
import { AscConfirmDialogComponent } from 'src/app/shared/components/asc-confirm-dialog/asc-confirm-dialog.component';
import { AscConfirmDialogData } from 'src/app/shared/interfaces/AscConfirmDialogData';
import { ConfigureableTableColumn } from 'src/app/shared/interfaces/ConfigureableTableColumn';
import { DialogResponse } from 'src/app/shared/interfaces/DialogResponse';
import { StorageOperations } from 'src/app/shared/interfaces/StorageOperations';
import { AscJsonError } from '../../../shared/interfaces/AscJsonError';
import { DeviceFile, DeviceFileMetaData } from '../../interfaces/DeviceFile';
import { DeviceFileDataSource } from '../../interfaces/DeviceFileDataSource';
import { DeviceFileMetaDataSource } from '../../interfaces/DeviceFileMetaDataSource';
import { FileTransferService } from '../../services/app-filetransfer.service';
import { FileApiService } from '../../services/file-api.service';
import { PackagedDownloadService } from '../../services/packaged-download.service';
import { FileUploadDialogComponent } from '../file-upload-dialog/file-upload-dialog.component';

@Component({
    selector: 'app-device-filestorage',
    templateUrl: './device-filestorage.component.html',
    styleUrls: ['./device-filestorage.component.scss'],
    standalone: false
})
export class DeviceFilestorageComponent implements OnInit, OnDestroy, StorageOperations {
  devicefiles$: Observable<unknown>;
  downloadFailed = false;
  searchFileControl = new FormControl('');

  @Input() device: Device;
  @ViewChild('packageMenuTrigger') packageMenuTrigger: MatMenuTrigger;

  dataSource: DeviceFileDataSource;
  pageables: ConfigPageEvent;
  sort: Sort;
  columns: ConfigureableTableColumn<DeviceFile>[] = [
    {
      name: 'name',
      header: 'Name',
      sortable: true,
    },
    {
      name: 'fileType',
      header: 'Type',
      sortable: true,
    },
    {
      name: 'size',
      header: 'Size',
      sortable: true,
    },
    {
      name: 'uploadOn',
      header: 'Uploaded On',
      sortable: true,
    },
    {
      name: 'actions',
      header: 'Actions',
      sortable: false,
    },
    {
      name: 'uploadStatus',
      header: '',
      sortable: false,
    },
  ];

  columnsMetaData: ConfigureableTableColumn<DeviceFileMetaData>[] = [
    {
      name: 'key',
      header: 'Key',
      sortable: false,
    },
    {
      name: 'value',
      header: 'Value',
      sortable: false,
    },
  ];
  colIdentifier = (col: DeviceFile) => col.fileId;
  selectedFileIds: number[] = [];

  metaDataSources: { [key: string]: DeviceFileMetaDataSource } = {};
  @ViewChild('filesTable') filesTable: AscConfigurableTableComponent<DeviceFile>;

  onload = false;
  deviceSub: Subscription;

  currentTransfer = 0;
  packageDownloadRunning = new BehaviorSubject(false);
  filePackageName = new FormControl<string | null>('');
  filePackageWithMetadata = new FormControl(false);
  caseNumber = new FormControl<string | null>('');

  constructor(
    private fileApi: FileApiService,
    private toasts: ToastsService,
    private dialog: MatDialog,
    private fileTransferService: FileTransferService,
    private store: AppStorageService,
    private packagedFileService: PackagedDownloadService
  ) {
    this.dataSource = new DeviceFileDataSource(this.fileApi, this.fileTransferService);
    this.getStorageData();

    window.onbeforeunload = () => this.saveStorageData();
  }
  getStorageData(): void {
    this.sort = {
      active: this.store.getValue('device_filestorage_sort_column') || '',
      direction: this.store.getValue('device_filestorage_sort_direction') || '',
    };

    this.pageables = {
      pageSize: this.store.getValue('device_filestorage_pagination_pagesize') || 10,
      pageIndex: 0,
      pageSizeOptions: [10, 20, 50],
    };
  }
  saveStorageData(): void {
    this.store.setValue('device_filestorage_sort_column', this.sort.active, 'string', true);
    this.store.setValue('device_filestorage_sort_direction', this.sort.direction, 'string', true);
    this.store.setValue('device_filestorage_pagination_pagesize', this.pageables.pageSize, 'number', true);
  }

  ngOnInit(): void {
    if (!this.device.id) {
      console.error('__DeviceFileStorageComponent__', 'deviceId is not provided');
      return;
    }
    this.loadDataSource();
    this.fileTransferService.finishedTransfer.subscribe((resp) => {
      if (resp !== this.currentTransfer) {
        this.currentTransfer = resp;
        this.loadDataSource();
      }
    });
    this.dataSource.dataSubject.subscribe((resp) => {
      resp.forEach((file) => {
        if (file.inTransfer) {
          this.filesTable.toggleExpandRow(file, false);
        }
      });
    });
  }
  onPagChange(pag: PageEvent) {
    this.pageables = {
      ...this.pageables,
      pageSize: pag.pageSize,
      pageIndex: pag.pageIndex,
    };
    this.loadDataSource();
  }
  onSortChange(sort: Sort) {
    this.sort = {
      direction: sort.direction,
      active: sort.active,
    };
    this.loadDataSource();
  }

  onSearch() {
    this.loadDataSource();
  }

  loadDataSource() {
    this.metaDataSources = {};
    this.dataSource.loadDeviceFiles(
      this.searchFileControl.value || '',
      this.device.id,
      this.pageables.pageIndex,
      this.pageables.pageSize,
      this.sort?.active && this.sort.direction ? this.sort.active : '',
      this.sort?.direction || ''
    );
  }

  removeTransfer(elem: DeviceFile) {
    if (!elem.transfer) return;
    this.fileTransferService.removeTransfer(elem.transfer.id, this.device.id);
    this.loadDataSource();
  }

  rowExpanded(row: { element: DeviceFile; expanded: boolean }) {
    if (row.expanded) {
      this.getMetaDataSource(row.element);
    }
  }

  getDataSource(elem: DeviceFile): DeviceFileMetaDataSource {
    return this.metaDataSources[elem.fileId];
  }

  getMetaDataSource(dFile: DeviceFile) {
    const ds = new DeviceFileMetaDataSource(this.fileApi);
    ds.loadMetaData(dFile);
    ds.setMetaData(dFile);
    this.metaDataSources[dFile.fileId] = ds;

    return ds;
  }

  refreshMetadata(dFile: DeviceFile) {
    this.getDataSource(dFile).loadMetaData(dFile);
  }

  deleteFile(dFile: DeviceFile) {
    const dialogConfig: MatDialogConfig<AscConfirmDialogData<unknown, unknown>> = {
      data: {
        dialogType: 'warning',
        title: 'Delete ' + dFile.name,
        message: `Delete file ${dFile.name} for device ${this.device.serialNumber} • ${this.device.deviceType}`,
        actionLabel: 'Delete',
        action: {
          call: () => this.fileApi.deleteFile(dFile.fileId.toString()),
          successHandler: () => `Deleted file ${dFile.name}`,
          errorHandler: () => `Could not delete file ${dFile.name}`,
        },
      },
    };

    const dialogRef = this.dialog.open(AscConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((closed: DialogResponse<unknown>) => {
      if (closed.status === 'SUCCESS') {
        this.loadDataSource();
      }
    });
  }

  downloadFile(element: DeviceFile, index: number): void {
    element.downloading = true;
    this.startDownloadProcess(element, index);
  }

  fileIsSelected(fileId: number) {
    return this.selectedFileIds.includes(fileId);
  }

  toggleFileUidForDownload(selected: boolean, fileId: number) {
    if (!selected && this.selectedFileIds.includes(fileId)) {
      this.selectedFileIds.splice(this.selectedFileIds.indexOf(fileId), 1);
    } else if (selected) {
      this.selectedFileIds.push(fileId);
    }
  }

  // if no case number is specified than provide the device type and the serial number of that device
  startDownloadPackaged(packageName: string | null, withMetadata?: boolean | null, caseNumber?: string | null) {
    this.packageMenuTrigger.closeMenu();
    this.packagedFileService
      .startPackagingFilesForDownload(this.selectedFileIds, withMetadata, caseNumber)
      .pipe(switchMap((taskId) => this.packagedFileService.getPackagingFilesStatus(taskId, packageName)))
      .subscribe();
  }

  // this method will be called recursively if there is a multipart download
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  startDownloadProcess(element: DeviceFile, index: number): void {
    this.fileApi.getPreSignedUrlForDownload(element.fileId, this.caseNumber.value).subscribe({
      next: (resp) => {
        console.log('--------Received pre-signed URL for download--------');

        const dwnUrl = resp.preSignedUrl == null ? '' : resp.preSignedUrl;
        const link = document.createElement('a');
        link.href = dwnUrl;
        //dwnUrl.substring
        link.download = dwnUrl.substring(dwnUrl.lastIndexOf('/') + 1);
        try {
          link.click();
          console.log('---------Received File------------');
          element.downloading = false;
          this.toasts.raise({ message: 'Please check your download folder', title: 'Download file' }, 'SUCCESS', false);
        } catch (error) {
          element.downloading = false;
          console.error(error);
          this.toasts.raise({ message: 'Could not download file', title: 'Download file' }, 'ERROR');
        }
      },
      error: (error) => {
        console.error(error);
        this.toasts.raise({ message: 'Could not download file', title: 'Download file', error: error }, 'ERROR');
      },
    });
  }

  openUploadFileDialog(): void {
    const dialogRefFileUpload = this.dialog.open(FileUploadDialogComponent, {
      width: '600px',
      height: '500px',
      data: {
        deviceId: this.device.id,
        deviceType: this.device.deviceType,
      },
    });
    dialogRefFileUpload.afterClosed().subscribe((result) => {
      if (result === 200) {
        this.loadDataSource();
      }
    });
  }

  parseFiles(): void {
    this.fileApi.parseFiles(this.device.id, this.selectedFileIds).subscribe({
      next: (response) => {
        const unableToParse = new Map(Object.entries(response.unableToParse));
        unableToParse.forEach((responseErr: AscJsonError) => {
          this.toasts.raise(
            {
              message: 'Unable to parse file because: ' + responseErr.message,
              title: 'Parse files',
            },
            'ERROR'
          );
        });
        this.toasts.raise({ message: 'Files are being parsed', title: 'Parse files' }, 'SUCCESS');
      },
      error: () => {
        this.toasts.raise({ message: 'Unable to parse any of the files', title: 'Parse files' }, 'ERROR');
      },
    });
  }

  cancelUpload(transfer: DeviceFile) {
    const fileName = transfer.name.substring(transfer.name.lastIndexOf('/') + 1);
    const dialogConf: MatDialogConfig<AscConfirmDialogData<unknown, unknown>> = {
      data: {
        dialogType: 'warning',
        title: 'Cancel file upload',
        message: `Cancel upload of file ${fileName}`,
        actionLabel: 'Cancel Upload',
        action: {
          call: () => of(this.fileTransferService.cancelTransfer(this.device.id, transfer.transfer.id)),
          errorHandler: () => 'Could not cancel upload for file ' + fileName,
          successHandler: () => 'Upload of file ' + fileName + ' was cancelled.',
        },
      },
    };
    const dialogRef = this.dialog.open(AscConfirmDialogComponent, dialogConf);
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp.status === 'SUCCESS') {
        this.loadDataSource();
      }
    });
  }

  ngOnDestroy(): void {
    this.saveStorageData();
  }
}
