<div class="asc-option-select-container" (click)="[$event.stopPropagation()]">
  <div class="search-input-container">
    @if (props.withSearch) {
    <app-asc-text-input
      [control]="searchControl"
      [name]="'search1'"
      type="search"
      [clear]="true"
      (keyupEnter)="addIncomplete()"
      [placeholder]="props.inputPlaceholder ? props.inputPlaceholder : 'Search'"
    ></app-asc-text-input>
    } @if (props.addIncomplete) {
    <app-asc-icon-button (btnClicked)="addIncomplete()">add</app-asc-icon-button>
    }
  </div>

  <div class="options-container">
    @if (props.type === 'category') { @if (filteredSelected$ | async; as sels) { @if (sels.length > 0) {
    <div class="cat-sepearator-label">Selected</div>
    } @for (sel of sels; track sel) {
    <button (click)="removeOption(sel)" class="selector-button">
      <span>{{ props.optionLabelPredicate(sel) }}</span> <mat-icon>close</mat-icon>
    </button>
    } } @if (filteredUnselected$ | async; as unsels) { @if (unsels.length > 0) {
    <div class="cat-sepearator-label">Available</div>
    } @for (unsel of unsels; track unsel) {
    <button class="selector-button" (click)="addOption(unsel)">
      <span>{{ props.optionLabelPredicate(unsel) }}</span> <mat-icon>add</mat-icon>
    </button>
    } } } @if (props.type === 'check-box') { @for (sel of checkBoxData$ | async; track sel) {
    <button class="selector-button" (click)="checkBoxClicked(sel)">
      <span>{{ props.optionLabelPredicate(sel.data) }}</span>
      <mat-checkbox [disableRipple]="true" (click)="($event.preventDefault)" [checked]="sel.selected"></mat-checkbox>
    </button>
    } }
  </div>
</div>
