import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
    selector: 'app-asc-dialog-wrapper',
    templateUrl: './asc-dialog-wrapper.component.html',
    styleUrls: ['./asc-dialog-wrapper.component.scss'],
    standalone: false
})
export class AscDialogWrapperComponent implements OnInit {
  @Input() dialogType: 'default' | 'advisory' | 'warning' | 'error' = 'default';
  constructor(private elRef: ElementRef<AscDialogWrapperComponent>, private renderer: Renderer2) {}
  ngOnInit(): void {
    this.renderer.addClass(this.elRef.nativeElement, this.dialogType + '-border');
  }
}
