<sub-header label="Device Type Management"> </sub-header>
<div class="c-main-container">
  <div style="display: flex; align-items: first baseline; justify-content: space-between; margin: 0 0 30px 0">
    <app-asc-text-input placeholder="Search Device Type" [control]="searchControl" prefixIcon="search" [clear]="true"
    (cleared)="searchDeviceTypes()" (keyupEnter)="searchDeviceTypes()"></app-asc-text-input>
    <app-asc-inline-button (btnClicked)="openAddDeviceTypeDialog()">Add Device Types <mat-icon
    inlineIcon>add</mat-icon></app-asc-inline-button>
  </div>

  <app-asc-configurable-table class="device-type-table" [dataSource]="dataSource" [columns]="columns"
    [pageables]="pageables" [sortProps]="sort" (paginatorChange)="pagChange($event)" (sortChange)="sortChange($event)"
    [columnCells]="[deviceTypeTemp, modelTypeTemp, dhsTemp, archivesTemp, gateKeeperTmp, quickConnectionTmp, sMaxMatchTemp, inUseTmp, createdTemp, actionTmp]"
    [expandable]="tableExpand"
    [colIdentifier]="columnIdentifier">
  </app-asc-configurable-table>

  <ng-template let-tableElem="tableElem" #deviceTypeTemp>
    {{ tableElem.deviceType }}
  </ng-template>
  <ng-template let-tableElem="tableElem" #modelTypeTemp>
    {{ tableElem.modelType }}
  </ng-template>
  <ng-template let-tableElem="tableElem" #dhsTemp>
    <mat-icon [ngStyle]="{ color: tableElem.updateDhs ? 'green' : '#236fb8' }"
      [appAscTooltip]="tableElem.updateDhs ? 'Update DHS' : 'Not Update DHS'">
      {{ tableElem.updateDhs ? 'check_circle' : 'cancel' }}
    </mat-icon>
  </ng-template>
  <ng-template let-tableElem="tableElem" #archivesTemp>
    <mat-icon [ngStyle]="{ color: tableElem.unpackUploadedArchives ? 'green' : '#236fb8' }"
      [appAscTooltip]="tableElem.unpackUploadedArchives ? 'Unpack Archives' : 'Not Unpack Archives'">{{
    tableElem.unpackUploadedArchives ? 'check_circle' : 'cancel' }}</mat-icon>
  </ng-template>
  <ng-template let-tableElem="tableElem" #gateKeeperTmp>
    <mat-icon [ngStyle]="{ color: tableElem.gateKeeperConnection? 'green' : '#236fb8' }"
      [appAscTooltip]="tableElem.gateKeeperConnection ? 'The Gate-Keeper remote session type is available.' : 'The Gate-Keeper remote session type is not available.'">{{
    tableElem.gateKeeperConnection ? 'check_circle' : 'cancel' }}</mat-icon>
  </ng-template>
  <ng-template let-tableElem="tableElem" #quickConnectionTmp>
    <mat-icon [ngStyle]="{ color: tableElem.quickConnection? 'green' : '#236fb8' }"
      [appAscTooltip]="tableElem.quickConnection ? 'The Quick-Connection remote session type is available.' : 'The Quick-Connection remote session type is not available.'">{{
    tableElem.quickConnection ? 'check_circle' : 'cancel' }}</mat-icon>
  </ng-template>
  <ng-template let-tableElem="tableElem" #sMaxMatchTemp>
    <mat-icon [ngStyle]="{ color: tableElem.smaxMatch ? 'green' : '#236fb8' }"
      [appAscTooltip]="tableElem.smaxMatch ? 'This device type is matches to a type in SMax.' : 'This device type does not match any type in SMax.'">
      {{ tableElem.smaxMatch ? 'check_circle' : 'cancel' }}
    </mat-icon>
  </ng-template>
  <ng-template let-tableElem="tableElem" #inUseTmp
    [appAscTooltip]="'Specifies the number of devices that are of this device type.'">
    {{ tableElem.inUse }}
  </ng-template>
  <ng-template let-tableElem="tableElem" #createdTemp>{{ tableElem.createdOnDate | date : 'yyyy-MM-dd, HH:mm:ss'
  }}</ng-template>
  <ng-template let-tableElem="tableElem" #actionTmp>
    <app-asc-icon-button [appAscTooltip]="'Delete Device Type'"
    (btnClicked)="openDeleteConfirmDialog(tableElem)">delete</app-asc-icon-button>
    <app-asc-icon-button [appAscTooltip]="'Edit the Device Type'"
    (btnClicked)="openEditDialog(tableElem)">edit</app-asc-icon-button>
  </ng-template>

  <ng-template #tableExpand let-tableElem="tableElem">
    <div class="device-type-details">
      @if (tableElem) {
        <div class="details-col">
          <div>Special Processing Pattern:</div>
        </div>
        <div class="details-col">
          <div>{{ tableElem.specialProcessingPattern }}</div>
        </div>
        <div class="vertical-divider"></div>
        <div class="details-col">
          <div>Upload File Approval Rule:</div>
        </div>
        <div class="details-col">
          <div>{{ tableElem.uploadApprovalRule }}</div>
        </div>
      }
    </div>
  </ng-template>
</div>