<div style="margin: 20px">
  <h2 mat-dialog-title>Details</h2>

  @for (dataSource of dataSources; track dataSource; let i = $index) {
    <div>
      @if (dataSource.data.values !== null) {
        <div>
          <h4>{{ data.groups[i].name }}</h4>
          <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
            <!-- Key Column -->
            <ng-container matColumnDef="key">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Key </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{ element.key }} </mat-cell>
            </ng-container>
            <!-- Value Column -->
            <ng-container matColumnDef="value">
              <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
              <mat-cell *matCellDef="let element" class="info-cell"> {{ element.value }} </mat-cell>
            </ng-container>
            <!-- Column header-->
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <!-- Rows-->
            <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
          </mat-table>
        </div>
      }
    </div>
  }
  <mat-dialog-actions>
    <button mat-button mat-dialog-close style="margin: auto; text-align: center">Close</button>
  </mat-dialog-actions>
</div>
