import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateRange } from '@angular/material/datepicker';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject, Observable, catchError, map, of, shareReplay } from 'rxjs';
import { DeviceType } from 'src/app/device/interfaces/DeviceType';
import { DetailsDialogComponent, DetailsDialogData } from 'src/app/shared/components/details-dialog/details-dialog.component';
import { SharedApiService } from 'src/app/shared/services/shared-api.service';
import { FailedJobsDataSource } from '../../interfaces/FailedJobsDataSource';
import { FailedJob } from '../../interfaces/Job';
import { FailedJobsApiService } from '../../services/failed-jobs-api.service';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Moment } from 'moment';
import { AscAutocompleteProperties } from 'src/app/asc-forms/interfaces/AscAutocompleteProperties';
import { DateRangePickerResponse, DateRangeRelativeResponse } from 'src/app/asc-forms/interfaces/DateRangePickerResponse';
import { DateRangeUtils } from 'src/app/asc-forms/interfaces/DateRangeUtils';
import { PackagedDownloadService } from 'src/app/device-file/services/packaged-download.service';
import { ToastsService } from 'src/app/error-handling/services/toasts.service';
import { AppStorageService } from 'src/app/shared/app-storage.service';
import { ConfigPageEvent } from 'src/app/shared/components/asc-configurable-table/asc-configurable-table.component';
import { AscConfirmDialogComponent } from 'src/app/shared/components/asc-confirm-dialog/asc-confirm-dialog.component';
import { AscConfirmDialogData } from 'src/app/shared/interfaces/AscConfirmDialogData';
import { ConfigureableTableColumn } from 'src/app/shared/interfaces/ConfigureableTableColumn';
import { DialogResponse } from 'src/app/shared/interfaces/DialogResponse';
import { ISO_DATE_FORMAT } from 'src/app/utils/date-formats';
import { DhsJobStatusHelper, getDhsJobInfo } from '../../interfaces/DhsJobUtils';

@Component({
    selector: 'app-failed-jobs-tab',
    templateUrl: './failed-jobs-tab.component.html',
    styleUrls: ['./failed-jobs-tab.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: ISO_DATE_FORMAT },
    ],
    standalone: false
})
export class FailedJobsTabComponent implements OnInit, OnDestroy, AfterViewInit {
  pageables: ConfigPageEvent;
  sort: Sort;
  timeRangeControl = new FormControl('');
  dateRange: DateRange<Moment | null> = new DateRange(null, null);
  relativeRange: DateRangeRelativeResponse | null = null;
  rangeButtonLabel: string;
  datePickerIndex = 0;
  dateRangeUtils: DateRangeUtils;
  jobTypeSearchProps: AscAutocompleteProperties<string> = {
    humanizeOption: (val) => val,
    filterPredicate: (val, search) => val.toLowerCase().includes(search.toLowerCase()),
    equals: (a, b) => a === b,
    multiple: true,
    placeholder: 'Job Type',
  };
  deviceTypeSearchProps: AscAutocompleteProperties<DeviceType> = {
    humanizeOption: (val) => val.deviceType,
    filterPredicate: (val, search) => val.deviceType.toLowerCase().includes(search.toLowerCase()),
    equals: (a, b) => a === b,
    placeholder: 'Device Type',
    comparator: (input, deviceType) => deviceType.deviceType === input,
  };
  errorTypeSearchProps: AscAutocompleteProperties<string> = {
    humanizeOption: (val) => val,
    filterPredicate: (val, search) => val.toLowerCase().includes(search.toLowerCase()),
    equals: (a, b) => a === b,
    placeholder: 'Error Type',
  };
  // FormControls
  jobTypeControl = new FormControl<string[]>([]);
  errorTypeControl = new FormControl<string>('');
  deviceTypeControl = new FormControl<DeviceType | null>(null);
  serialNumberControl = new FormControl<string>('');
  fileNameControl = new FormControl<string>('');
  getDhsJobInfo = getDhsJobInfo;
  tableColumns: ConfigureableTableColumn<FailedJob>[] = [
    {
      sortable: true,
      name: 'type',
      header: 'Job Type',
    },
    {
      sortable: true,
      name: 'errorType',
      header: 'Error Type',
    },
    {
      sortable: true,
      name: 'deviceType',
      header: 'Device Type',
    },
    {
      sortable: true,
      name: 'deviceSerialNumber',
      header: 'Serial Number',
    },
    {
      sortable: true,
      name: 'failedAt',
      header: 'Failed At',
    },
    {
      sortable: false,
      name: 'actions',
      header: 'Actions',
    },
  ];
  addExpandableRow: (job: FailedJob) => boolean = (job) => job.type === 'DHS_NOTIFICATION';

  dataSource: FailedJobsDataSource;
  jobTypes$: Observable<string[]>;
  deviceTypes: DeviceType[];
  deviceTypesFiltered$ = new BehaviorSubject<DeviceType[]>([]);
  jobErrorTypes$: Observable<string[]>;
  jobErrorTypes: string[] = [];
  filteredErrorTypes$ = new BehaviorSubject<string[]>([]);
  deviceTypes$: Observable<DeviceType[]>;

  dhsHelper: DhsJobStatusHelper;
  failedAfter: string | undefined = undefined;
  failedBefore: string | undefined = undefined;

  constructor(
    private failedJobsApi: FailedJobsApiService,
    private sharedApi: SharedApiService,
    private matDialog: MatDialog,
    private datePipe: DatePipe,
    private toasts: ToastsService,
    private store: AppStorageService,
    private filePacakgingService: PackagedDownloadService
  ) {
    this.getStorageData();
    this.dateRangeUtils = new DateRangeUtils(this.datePipe);
    this.dataSource = new FailedJobsDataSource(failedJobsApi);
    this.dhsHelper = new DhsJobStatusHelper(this.failedJobsApi);
    this.deviceTypes$ = this.sharedApi.getDeviceTypeList().pipe(
      shareReplay(),
      map((deviceresp) => (deviceresp.content ? deviceresp.content : [])),
      map((deviceresp) => deviceresp.sort((d1, d2) => (d1.deviceType.toLowerCase() > d2.deviceType.toLowerCase() ? 1 : -1)))
    );

    this.jobTypes$ = this.failedJobsApi.getJobTypes().pipe(
      shareReplay(),
      map((jobTypes) => jobTypes.sort())
    );

    this.jobErrorTypes$ = this.failedJobsApi.getJobErrorTypes().pipe(
      shareReplay(),
      map((errorTypes) => errorTypes.sort())
    );

    window.onbeforeunload = () => this.saveStorageData();
  }

  ngAfterViewInit(): void {
    // calculate the drop down boxes panel witdh
    this.jobTypes$.subscribe((data) => this.calculatePanelWidth(data, '.filter-input', this.jobTypeSearchProps));
    this.deviceTypes$.subscribe((data) => {
      const devicetypeNames = data.map((deviceType) => deviceType.deviceType);
      this.calculatePanelWidth(devicetypeNames, '.filter-input', this.deviceTypeSearchProps);
    });
    this.jobErrorTypes$.subscribe((data) => this.calculatePanelWidth(data, '.filter-input', this.errorTypeSearchProps));
  }

  calculatePanelWidth(data: string[], className: string, properties: AscAutocompleteProperties<string> | AscAutocompleteProperties<DeviceType>): void {
    const maxText = data.sort((a, b) => b.length - a.length)[0];
    const element = document.querySelector(className);
    if (element) {
      // Create a canvas element
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const style = window.getComputedStyle(element);
      // Set the font to the same font as your control
      if (context) {
        context.font = `${style.fontWeight} ${style.fontSize} ${style.fontFamily}`;
        // measure the maximum text that should fit and some extra space
        properties.panelWidth = context.measureText(maxText).width + 100 + 'px';
      }
    }
  }

  ngOnInit(): void {
    this.loadFailedJobsFiltered();
  }

  getStorageData(): void {
    this.sort = {
      active: this.store.getValue('all_failedjobs_sort_column') || '',
      direction: this.store.getValue('all_failedjobs_sort_direction') || '',
    };
    this.pageables = {
      pageSize: this.store.getValue('all_failedjobs_pagination_pagesize') || 10,
      pageIndex: 0,
      pageSizeOptions: [10, 20, 50],
    };
    this.deviceTypeControl.setValue(this.store.getValue('all_failedjobs_filter_devicetype'));
    this.errorTypeControl.setValue(this.store.getValue('all_failedjobs_filter_errortype'));
    this.jobTypeControl.setValue(this.store.getValue('all_failedjobs_filter_jobtype'));
    this.serialNumberControl.setValue(this.store.getValue('all_failedjobs_filter_serialnumber'));
  }
  saveStorageData(): void {
    this.store.setValue('all_failedjobs_sort_column', this.sort.active, 'string', true);
    this.store.setValue('all_failedjobs_sort_direction', this.sort.direction, 'string', true);
    this.store.setValue('all_failedjobs_pagination_pagesize', this.pageables.pageSize, 'number', true);
    this.store.setValue('all_failedjobs_filter_devicetype', this.deviceTypeControl.value, 'object', true);
    this.store.setValue('all_failedjobs_filter_errortype', this.errorTypeControl.value, 'string', true);
    this.store.setValue('all_failedjobs_filter_jobtype', this.jobTypeControl.value, 'string', true);
    this.store.setValue('all_failedjobs_filter_serialnumber', this.serialNumberControl.value, 'string', true);
  }

  showDetails(job: FailedJob) {
    const failedAt = this.datePipe.transform(job.failedAt, 'yyyy-MM-dd, HH:mm');
    const dialogConfig: MatDialogConfig<DetailsDialogData<FailedJob>> = {
      minWidth: '50vw',
      data: {
        dialogTitle: 'Details',
        components: [
          {
            type: 'table',
            title: 'General',
            keyColumnId: 'key_1',
            keyColumnLabel: '',
            valueColumnId: 'value_1',
            valueColumnLabel: '',
            data: [
              {
                value: job.type,
                label: 'Job Type',
              },
              {
                value: job.deviceType,
                label: 'Device Type',
              },
              {
                value: job.deviceSerialNumber,
                label: 'Serial Number',
              },
              {
                value: failedAt ? failedAt : job.failedAt,
                label: 'Failed At',
              },
            ],
          },
          {
            type: 'table',
            title: 'Error',
            keyColumnId: 'key_3',
            keyColumnLabel: '',
            valueColumnId: 'value_3',
            valueColumnLabel: '',
            data: [
              {
                value: job.errorType,
                label: 'Error ID',
              },
              {
                label: 'Error Message',
                value: job.errorMessage,
              },
            ],
          },
        ],
      },
    };
    if (job.fileName && job.fileType && dialogConfig.data)
      dialogConfig.data.components = [
        dialogConfig.data.components[0],
        {
          type: 'table',
          title: 'File',
          keyColumnId: 'key_2',
          keyColumnLabel: '',
          valueColumnId: 'value_2',
          valueColumnLabel: '',
          data: [
            {
              label: 'Name',
              value: job.fileName,
            },
            {
              label: 'Type',
              value: job.fileType,
            },
          ],
        },
        dialogConfig.data.components[1],
      ];
    this.matDialog.open(DetailsDialogComponent, dialogConfig);
  }

  restartJob(job: FailedJob) {
    this.failedJobsApi
      .restartJob(job.id)
      .pipe(
        catchError((err) => {
          this.toasts.raise({ message: `Failed to restart job with ID ${job.id}`, title: 'Restart Job', error: err }, 'ERROR');
          return of();
        })
      )
      .subscribe((job) => {
        this.toasts.raise({ message: `Successfully restarted job with ID ${job.id}`, title: 'Restart Job' }, 'SUCCESS', false);
        if (job.type === 'DHS_NOTIFICATION') this.dhsHelper.getJobStatusInterval(job.id);
        if (job.type === 'DOWNLOAD_PACKAGED_FILES') this.getPackagedDownloadStatus(job);
        this.loadFailedJobsFiltered();
      });
  }

  getPackagedDownloadStatus(job: FailedJob) {
    if (!job.fileName) {
      this.toasts.raise({ title: 'Cannot restart failed job', message: 'No filename to reference the file package was provided.' }, 'ERROR');
      return;
    }
    this.filePacakgingService.getPackagingFilesStatus(job.fileName, 'log-file-package').subscribe();
  }

  deleteFailedJob(job: FailedJob) {
    const dialogConfig: MatDialogConfig<AscConfirmDialogData<unknown, unknown>> = {
      data: {
        action: {
          call: () => this.failedJobsApi.deleteFailedJob(job.id),
          successHandler: () => `Failed job ${job.type} for the device ${job.deviceType} • ${job.deviceSerialNumber} was deleted`,
          errorHandler: () => `Could not delete failed job ${job.type} for the device ${job.deviceType} • ${job.deviceSerialNumber}`,
        },
        message: `Are you sure you want to delete the failed job of type ${job.type} for the device ${job.deviceType} • ${job.deviceSerialNumber}`,
        title: 'Delete failed job',
        dialogType: 'warning',
      },
    };

    const dialogRef = this.matDialog.open<AscConfirmDialogComponent<unknown, DialogResponse>, AscConfirmDialogData, DialogResponse>(
      AscConfirmDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp?.status === 'SUCCESS') {
        if (job.type === 'DHS_NOTIFICATION') this.dhsHelper.getJobStatus(job.id);
        this.loadFailedJobsFiltered();
      }
    });
  }

  applyFilter() {
    this.loadFailedJobsFiltered();
  }

  loadFailedJobsFiltered() {
    this.dataSource.loadFailedJobs(
      { page: this.pageables.pageIndex, size: this.pageables.pageSize, sort: `${this.sort.active},${this.sort.direction}` },
      {
        deviceSerialNumber: this.serialNumberControl.value || '',
        deviceType: this.deviceTypeControl.value?.deviceType || '',
        jobType: this.jobTypeControl.value || undefined,
        errorType: this.errorTypeControl.value || '',
        failedAfter: this.failedAfter,
        failedBefore: this.failedBefore,
        fileName: this.fileNameControl.value || undefined,
      }
    );
  }

  getRelativeRange(relative: DateRangePickerResponse<'relative'>) {
    this.relativeRange = relative.range;
    this.timeRangeControl.setValue(this.dateRangeUtils.getRelativeLabel(this.relativeRange));
    this.datePickerIndex = 1;
    if (relative.range?.seconds) {
      this.failedAfter = new Date(new Date().getTime() - relative.range?.seconds * 1000).toISOString();
      this.failedBefore = new Date().toISOString();
    }

    this.loadFailedJobsFiltered();
  }
  getAbsoluteRange(absolute: DateRangePickerResponse<'absolute'>) {
    this.dateRange = absolute.range;
    this.timeRangeControl.setValue(this.dateRangeUtils.getAbsoluteLabel(this.dateRange));
    this.datePickerIndex = 0;
    if (this.dateRange.start && this.dateRange.end) {
      this.failedAfter = this.dateRange.start.toDate().toISOString();
      this.failedBefore = this.dateRange.end.toDate().toISOString();
    }
    this.loadFailedJobsFiltered();
  }

  clearDateRange() {
    this.dateRange = new DateRange(null, null);
    this.relativeRange = null;
    this.timeRangeControl.setValue(this.dateRangeUtils.getRelativeLabel(this.relativeRange));
    this.failedAfter = undefined;
    this.failedBefore = undefined;
    this.loadFailedJobsFiltered();
  }

  pagChange(pagEvent: PageEvent) {
    this.pageables = {
      ...this.pageables,
      pageSize: pagEvent.pageSize,
      pageIndex: pagEvent.pageIndex,
    };
    this.loadFailedJobsFiltered();
  }
  sortChange(sortEvent: Sort) {
    this.sort = sortEvent;
    this.loadFailedJobsFiltered();
  }
  rowExpansionChange(row: { element: FailedJob; expanded: boolean }) {
    if (!row.expanded) return;
    this.dhsHelper.getJobStatus(row.element.id);
  }

  ngOnDestroy(): void {
    this.saveStorageData();
  }
}
