import { Pipe, PipeTransform } from '@angular/core';
import { Device, DeviceStatus, NextDeviceStatusProperties, NextDeviceStatusFromCurrent } from '../interfaces/Device';
import { NEXT_DEVICE_STATUS } from '../device.constants';

@Pipe({
    name: 'deviceActions',
    standalone: false
})
export class DeviceActionsPipe implements PipeTransform {
  transform(device: Device, ...args: unknown[]): NextDeviceStatusProperties<DeviceStatus>[] | null {
    const next = NEXT_DEVICE_STATUS[device.status];
    next?.map((_n) => {
      if (!_n.matIcon) {
        _n.matIcon = 'arrow_forward';
      }
      return _n;
    });

    return next ? next : null;
  }
}
