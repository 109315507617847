<sub-header label="Failed Jobs Management"></sub-header>
<div class="c-main-container">
  <div class="filter-row" (keyup.enter)="applyFilter()">
    <app-asc-autocomplete
      class="filter-input"
      [properties]="jobTypeSearchProps"
      [data]="jobTypes$"
      [control]="jobTypeControl"
      (selectionChange)="loadFailedJobsFiltered()"
    ></app-asc-autocomplete>
    <app-asc-autocomplete
      class="filter-input error-types"
      [properties]="errorTypeSearchProps"
      [data]="jobErrorTypes$"
      [control]="errorTypeControl"
      (selectionChange)="loadFailedJobsFiltered()"
    ></app-asc-autocomplete>
    <br />
    <app-asc-autocomplete
      class="filter-input"
      [properties]="deviceTypeSearchProps"
      [data]="deviceTypes$"
      [control]="deviceTypeControl"
      (selectionChange)="loadFailedJobsFiltered()"
    ></app-asc-autocomplete>
    <app-asc-text-input
      class="filter-input"
      [clear]="true"
      placeholder="Serial Number"
      (keyupEnter)="loadFailedJobsFiltered()"
      [control]="serialNumberControl"
      (cleared)="loadFailedJobsFiltered()"
    ></app-asc-text-input>
    <app-asc-text-input
      class="filter-input"
      [clear]="true"
      placeholder="File Name"
      (keyupEnter)="loadFailedJobsFiltered()"
      [control]="fileNameControl"
      (cleared)="loadFailedJobsFiltered()"
    ></app-asc-text-input>
    <app-date-range-picker
      class="filter-input"
      [dateRangeIn]="dateRange"
      [timeRange]="relativeRange"
      (relativeRange)="getRelativeRange($event)"
      (absoluteRange)="getAbsoluteRange($event)"
      >
      <app-asc-text-input
        class="date-input"
        maxWidth="260px"
        [control]="timeRangeControl"
        [useAsTrigger]="true"
        datePickerTrigger
        prefixIcon="arrow_drop_down"
        placeholder="Time Range"
        [clear]="true"
        (cleared)="clearDateRange()"
      ></app-asc-text-input>
    </app-date-range-picker>
  </div>

  <div class="mat-elevation-z2" style="margin: 0px 3vw 25px 3vw">
    <app-asc-configurable-table
      [dataSource]="dataSource"
      class="failed-jobs-table"
      [columns]="tableColumns"
      [columnCells]="[jobTypeTemp, errorTypeTemp, deviceTypeTemp, serialNumberTemp, failedAtTemp, actionsTemp]"
      [pageables]="pageables"
      [sortProps]="sort"
      [addExpandableRow]="addExpandableRow"
      (expansionChange)="rowExpansionChange($event)"
      (paginatorChange)="pagChange($event)"
      (sortChange)="sortChange($event)"
      >
    </app-asc-configurable-table>

    <ng-template #jobTypeTemp let-tableElem="tableElem">
      {{ tableElem.type }}
    </ng-template>
    <ng-template #errorTypeTemp let-tableElem="tableElem">
      {{ tableElem.errorType }}
    </ng-template>
    <ng-template #deviceTypeTemp let-tableElem="tableElem">
      {{ tableElem.deviceType }}
    </ng-template>
    <ng-template #serialNumberTemp let-tableElem="tableElem">
      {{ tableElem.deviceSerialNumber }}
    </ng-template>
    <ng-template #failedAtTemp let-tableElem="tableElem">
      {{ tableElem.failedAt | date : 'yyyy-MM-dd, HH:mm:ss' }}
    </ng-template>
    <ng-template #actionsTemp let-tableElem="tableElem">
      <app-asc-inline-button class="action-button" (click)="[$event.stopPropagation(), restartJob(tableElem)]">
        <mat-icon inlineIcon>replay</mat-icon>
        Restart
      </app-asc-inline-button>
      <app-asc-inline-button class="action-button" (click)="[$event.stopPropagation(), showDetails(tableElem)]">
        <mat-icon inlineIcon>info</mat-icon>
        Details
      </app-asc-inline-button>
      <app-asc-inline-button
        class="action-button"
        (click)="[$event.stopPropagation(), deleteFailedJob(tableElem)]"
        [appAscTooltip]="tableElem.type === 'DHS_NOTIFICATION' ? 'Delete next action' : 'Delete job'"
        >
        <mat-icon inlineIcon>delete</mat-icon>
        Delete
      </app-asc-inline-button>
    </ng-template>
    <ng-template #tableRowExpansion let-tableElem="tableElem">
      @if (dhsHelper.dhsJobsInfos[tableElem.id]) {
        @if ({
          status: dhsHelper.dhsJobsInfos[tableElem.id].jobStatus | async,
          error: dhsHelper.dhsJobsInfos[tableElem.id].jobStatusError | async
          }; as jobStatus) {
          @if (jobStatus.status) {
            @if (getDhsJobInfo(jobStatus.status); as stats) {
              <div class="dhs-job-info">
                <mat-icon [ngClass]="'dhs-status-icon ' + stats.iconClass" [ngStyle]="{ color: stats.color }">
                  {{ stats.icon }}
                </mat-icon>
                <p>{{ stats.message }}</p>
              </div>
              <div class="dhs-next-action-info">
                <h4>Next Action:</h4>
                <div>{{ stats.action }}</div>
              </div>
            }
          } @else {
            @if (!jobStatus.error) {
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            }
            @if (jobStatus.error) {
              <div>
                <mat-error>Can not get DHS Job status information</mat-error>
              </div>
            }
          }
        }
      }
    </ng-template>
  </div>
</div>
