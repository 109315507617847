import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-message-snackbar',
    templateUrl: './message-snackbar.component.html',
    styleUrls: ['./message-snackbar.component.scss'],
    standalone: false
})
export class MessageSnackbarComponent {
  snackbarData: BehaviorSubject<MessageSnackbarData>;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) snackbarData: BehaviorSubject<MessageSnackbarData>,
    private snackBarRef: MatSnackBarRef<MessageSnackbarComponent>
  ) {
    this.snackbarData = snackbarData;
  }
}

export type MessageSnackbarData = {
  message: string;
  icon?: string;
  iconColor?: string;
};
