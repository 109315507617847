import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { BehaviorSubject, Observable, Subscription, map } from 'rxjs';
import { ErrorHandlerService } from 'src/app/error-handling/services/error-handler.service';
import { errorMessageFormatter } from 'src/app/error-handling/utils/error-message-transformer';
import { ConfigureableTableColumn } from '../../interfaces/ConfigureableTableColumn';
import { DataSourceBaseImpl } from '../../interfaces/DataSourceBase';

export interface ConfigPageEvent {
  pageSizeOptions: number[];
  pageIndex: number;
  pageSize: number;
}

@Component({
    selector: 'app-asc-configurable-table',
    templateUrl: './asc-configurable-table.component.html',
    styleUrls: ['./asc-configurable-table.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', padding: '0 42px 0' })),
            state('expanded', style({ height: '*', padding: '0 42px 16px' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: false
})
export class AscConfigurableTableComponent<D = any> implements OnInit, AfterViewInit, OnDestroy {
  afterViewInit$ = new BehaviorSubject<boolean>(false);
  displayedColumns: string[];

  expandedRows: { [key: string | number]: boolean } = {};

  initSortCol: string;
  initSortDirection: SortDirection;

  @Input() dataSource: DataSourceBaseImpl<D>;
  @Input() columns: ConfigureableTableColumn<D>[] = [];

  @Input() noDataMessage: string | undefined;
  @Input() errorMessage: string | undefined;
  @Input() errorMessageFromDS = false;

  @Input() expandable: TemplateRef<unknown> | null = null;

  @Input() columnCells: TemplateRef<unknown>[] = [];

  @Input() headerCells?: { index: number; template: TemplateRef<unknown> }[];

  @Input() showPaginator = true;

  @Input() colIdentifier: (elem: D) => number | string = (elem: D) => (elem as typeof elem & { id: string })['id'];

  @Input() pageables: ConfigPageEvent | null = null;

  @Input() addExpandableRow: (elem: D) => boolean = () => true;

  @Input() sortProps: Sort;
  @Input() size: 'small' | 'medium' | 'large';

  @Output() paginatorChange: EventEmitter<PageEvent> = new EventEmitter();
  @Output() sortChange: EventEmitter<Sort> = new EventEmitter();
  @Output() expansionChange: EventEmitter<{ element: D; expanded: boolean }> = new EventEmitter();

  pagSub$: Subscription;
  @ViewChild(MatPaginator) matPag: MatPaginator;
  sortSub$: Subscription;
  @ViewChild(MatSort) matSort: MatSort;

  dataSourceError$: Observable<string>;

  constructor(private renderer: Renderer2, private elem: ElementRef, private errorHandler: ErrorHandlerService) {}

  ngOnInit() {
    this.displayedColumns = this.columns.map((col) => col.name);
    this.dataSourceError$ = this.dataSource.errorSubject.pipe(
      map((errResp) => {
        if (!errResp) {
          return this.errorMessage || 'Something went wrong!';
        }
        return this.getErrorMessage(errResp);
      })
    );
    this.initialSort();
    if (!this.expandable) {
      return;
    }
    this.columns.unshift({ name: '_expandable_col_', header: '', sortable: false });
    this.displayedColumns.unshift('_expandable_col_');
    if (this.size === 'small') {
      this.renderer.addClass(this.elem.nativeElement, 'asc-table-small');
    }
  }
  getErrorMessage(err: HttpErrorResponse | Error) {
    const errResp = this.errorHandler.httpErrorToErrorResponse(err);
    const params = errResp.parameters ? errResp.parameters : [];
    return errorMessageFormatter(errResp.message, ...params);
  }

  getHeaderCell(colIndex: number) {
    const cell = this.headerCells?.filter((cell) => cell.index === colIndex)[0].template || undefined;
    return cell;
  }

  initialSort() {
    if (!this.sortProps) return;
    if (!this.displayedColumns.some((col) => col === this.sortProps.active)) return;
    this.initSortCol = this.sortProps.active;
    this.initSortDirection = this.sortProps.direction;
  }

  listenToPaginator() {
    if (!this.matPag) return;
    this.pagSub$ = this.matPag.page.subscribe((resp) => {
      this.paginatorChange.emit(resp);
    });
  }

  listenToSort() {
    this.sortSub$ = this.matSort.sortChange.subscribe((resp) => {
      this.sortChange.emit(resp);
    });
  }

  listenToExpansion(elem: D, expanded: boolean) {
    this.expansionChange.emit({ element: elem, expanded: expanded });
  }

  ngAfterViewInit(): void {
    if (this.pageables) this.listenToPaginator();
    if (this.columns.some((col) => col.sortable)) this.listenToSort();
  }

  toggleExpandRow(elem: D, expanded: boolean) {
    const key = this.colIdentifier(elem);
    if (!this.addExpandableRow(elem)) return;
    if (typeof key === 'string' || typeof key === 'number') {
      this.expandedRows[key] = !this.expandedRows[key];
      this.listenToExpansion(elem, !expanded);
    }
  }

  ngOnDestroy(): void {
    if (this.pagSub$) this.pagSub$.unsubscribe();
    if (this.sortSub$) this.sortSub$.unsubscribe();
  }
}
