<sub-header [label]="'Assign ' + ((dlc | async)?.displayName || 'Downloadable Content')"></sub-header>

<div class="search-props">
  <app-asc-autocomplete [properties]="optionControlProps" [data]="optionData" [control]="optionControl" [clear]="false"></app-asc-autocomplete>
  <app-asc-text-input
    prefixIcon="search"
    placeholder="Search Device"
    [clear]="true"
    (keyupEnter)="loadData()"
    [control]="searchControl"
    (cleared)="loadData()"
  ></app-asc-text-input>
  <app-asc-text-input [placeholder]="'Case Number'" [control]="caseNumber" [appAscTooltip]="'Authorize assign or revoke by a case number'"></app-asc-text-input>
  @if (optionControl.value === 'Not Delivered') {
  <app-asc-inline-button [disabled]="selection.isEmpty() || inAction" (btnClicked)="assignDlc(caseNumber.value)"> Assign Content </app-asc-inline-button>
  }
</div>

<app-asc-configurable-table
  [pageables]="pageables"
  (paginatorChange)="onPagChange($event)"
  [sortProps]="sort"
  (sortChange)="onSortChange($event)"
  [dataSource]="dataSource"
  [columns]="columns"
  [columnCells]="[selectTemp, deviceDtTemp, deviceSnTemp]"
  [headerCells]="[{ index: 0, template: firstHeader }]"
>
</app-asc-configurable-table>

<ng-template #deviceDtTemp let-tableElem="tableElem">
  <div>{{ tableElem.deviceType }}</div>
</ng-template>

<ng-template #deviceSnTemp let-tableElem="tableElem">
  <div>{{ tableElem.serialNumber }}</div>
</ng-template>

<ng-template #selectTemp let-tableElem="tableElem">
  @if (optionControl.value === 'Not Delivered') {
  <mat-checkbox #rowCheckbox (click)="$event.stopPropagation()" (change)="selectDevice(tableElem)"></mat-checkbox>
  } @if (optionControl.value === 'Delivered') {
  <app-asc-icon-button appAscTooltip="Revoke" [disabled]="inAction" (btnClicked)="revokeDlc(tableElem, caseNumber.value)">block</app-asc-icon-button>
  }
</ng-template>

<ng-template #firstHeader>
  @if (optionControl.value === 'Not Delivered') {
  <mat-checkbox
    (change)="selectPage()"
    [indeterminate]="allIndertimnate"
    [checked]="allChecked"
    [disabled]="dataSource.isLoading$ | async"
    appAscTooltip="Select all on current page"
    class="header-checkbox"
  ></mat-checkbox>
  } @if (optionControl.value === 'Delivered') { Action }
</ng-template>
