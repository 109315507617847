@if (optionSelectProps.searchable) {
  <app-asc-text-input
    #searchInput
    class="search-input"
    (cleared)="clearInput()"
    name="optionSelect"
    [label]="optionSelectProps.label"
    [control]="searchControl"
    type="search"
    [matMenuTriggerFor]="optionsMenu"
    [clear]="true"
    (blured)="blurInput()"
    >
  </app-asc-text-input>
}

@if (optionSelectProps.error) {
  <div class="input-error-wrapper">
    @if (isError$ | async) {
      <div>{{ optionSelectProps.error }}</div>
    }
  </div>
}

<mat-menu #optionsMenu="matMenu" class="asc-dropdown" [ngStyle]="{ minWidth: minWidth + 'px' }">
  <ng-template matMenuContent>
    @for (option of filteredData$ | async; track option) {
      <div class="select-option" (click)="optionSelected(option)">
        @if (optionSelectProps.searchable) {
          <mat-icon class="search-icon">search</mat-icon>
          } {{ optionSelectProps.selectLabelPredicate(option) }}
        </div>
      }
    </ng-template>
  </mat-menu>
