{
  "name": "alcon-frontend",
  "version": "1.23.0",
  "scripts": {
    "ng": "ng",
    "replace-version": "node ./replace.build.js",
    "start": "ng serve",
    "build": "ng build && npm run replace-version",
    "build-prod": "ng build --configuration production && npm run replace-version",
    "build-dev": "ng build --configuration=dev && npm run replace-version",
    "build-demo": "ng build --configuration=demo && npm run replace-version",
    "build-qa": "ng build --configuration=qa && npm run replace-version",
    "build-vandv": "ng build --configuration=vandv && npm run replace-version",
    "build-stg": "ng build --configuration=stg && npm run replace-version",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "protractor": "protractor",
    "webdriver": "webdriver-manager update",
    "pretty": "prettier --write \"./**/*.{ts,html,css,scss,json}\"",
    "pretty-check": "prettier --check \"./**/*.{ts,html,css,scss,json}\"",
    "prettier-watch": "onchange \"src/**/*\" -- prettier --loglevel warn --write {{changed}}",
    "prettify": "prettier --write ",
    "enable-pre-commit-hook": "node create-pre-commit-hook.js"
  },
  "private": true,
  "dependencies": {
    "@angular-devkit/build-angular": "^19.2.3",
    "@angular/animations": "^19.2.2",
    "@angular/cdk": "^19.2.3",
    "@angular/common": "^19.2.2",
    "@angular/compiler": "^19.2.2",
    "@angular/core": "^19.2.2",
    "@angular/forms": "^19.2.2",
    "@angular/material": "^19.2.3",
    "@angular/material-moment-adapter": "^19.2.3",
    "@angular/platform-browser": "^19.2.2",
    "@angular/platform-browser-dynamic": "^19.2.2",
    "@angular/router": "^19.2.2",
    "@auth0/angular-jwt": "^5.0.2",
    "@aws-crypto/crc32": "^3.0.0",
    "@aws-crypto/crc32c": "^3.0.0",
    "@aws-crypto/sha1-browser": "^3.0.0",
    "@aws-crypto/sha256-browser": "^3.0.0",
    "@ng-idle/core": "^13.0.0",
    "@types/spark-md5": "^3.0.2",
    "alcon-frontend": "file:",
    "blob-util": "^2.0.2",
    "core-util-is": "^1.0.2",
    "cors": "^2.8.5",
    "fast-deep-equal": "^3.1.3",
    "file-saver": "^2.0.5",
    "filesize": "^10.0.5",
    "graphql": "^15.5.0",
    "ipaddr.js": "^2.0.1",
    "js-base64": "^3.6.0",
    "mime-types": "^2.1.35",
    "moment": "^2.29.4",
    "moment-timezone": "^0.5.47",
    "ms": "^2.1.3",
    "ngx-daterangepicker-material": "^4.0.1",
    "ngx-papaparse": "^5.1.0",
    "ngx-toastr": "^15.2.2",
    "rxjs": "^7.5.7",
    "spark-md5": "^3.0.2",
    "tslib": "^2.0.0",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-eslint/builder": "^19.2.1",
    "@angular-eslint/eslint-plugin": "^19.2.1",
    "@angular-eslint/eslint-plugin-template": "^19.2.1",
    "@angular-eslint/schematics": "^19.2.1",
    "@angular-eslint/template-parser": "^19.2.1",
    "@angular/cli": "^19.2.3",
    "@angular/compiler-cli": "^19.2.2",
    "@ngrx/schematics": "^11.1.0",
    "@types/file-saver": "^2.0.1",
    "@types/graphql": "^14.5.0",
    "@types/jasmine": "~3.6.0",
    "@types/node": "^12.11.1",
    "@typescript-eslint/eslint-plugin": "5.44.0",
    "@typescript-eslint/parser": "5.44.0",
    "eslint": "^8.31.0",
    "eslint-config-prettier": "^8.8.0",
    "husky": "^8.0.3",
    "jasmine-core": "~3.8.0",
    "jasmine-spec-reporter": "~5.0.0",
    "karma": "~6.4.1",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.0.3",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "^1.5.0",
    "onchange": "^7.1.0",
    "prettier": "2.8.8",
    "pretty-quick": "^3.1.3",
    "protractor": "^7.0.0",
    "replace-in-file": "^6.3.2",
    "sass": "^1.85.1",
    "schematics-scss-migrate": "^2.3.17",
    "ts-node": "~8.3.0",
    "typescript": "^5.8.2"
  }
}
