import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Device } from '../../interfaces/Device';
import { Router } from '@angular/router';
import { DeviceDetailsService } from '../../services/device-details.service';

@Component({
    selector: 'app-device-details-card',
    templateUrl: './device-details-card.component.html',
    styleUrls: ['./device-details-card.component.scss'],
    standalone: false
})
export class DeviceDetailsCardComponent {
  @Input() device: Device;
  @Output() reload = new EventEmitter<string>();

  constructor(private router: Router, private deviceDetails: DeviceDetailsService) {}

  goToParent(parentDeviceId: string) {
    this.router.navigate(['/devicedetails', parentDeviceId]);
  }
}
