import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedApiService } from 'src/app/shared/services/shared-api.service';
import { AuthenticationType } from '../../../../environments/AuthenticationType';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent implements OnInit {
  username = '';
  password = '';
  showError = false;
  showSessionExpiryError = false;
  hidePassword = true;
  loading = false;
  ascVersion: string;
  showOktaLoginButton = false;
  useOktaAutomaticLogin = false;
  error: Error | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private titleService: Title,
    private apiService: SharedApiService
  ) {}

  async ngOnInit(): Promise<void> {
    this.titleService.setTitle('ASC - Login');

    this.apiService.getVersion().subscribe({
      next: (version) => {
        this.ascVersion = version.version;
      },
      error: (error) => {
        console.log('Error in version api', error);
      },
    });

    // Okta logout redirect has to be handled
    this.auth.handleLogoutRedirect(this.route.snapshot.queryParams);

    if (environment.authenticationType == AuthenticationType.NONE) {
      this.auth.setToken('NOT NEEDED');
      this.auth.setExpirationIn(24 * 3600);
      await this.auth.afterLogin();
    } else if (!this.auth.getToken() && environment.authenticationType === AuthenticationType.OKTA) {
      this.useOktaAutomaticLogin = true;
      try {
        await this.auth.oktaLogin();
      } catch (error) {
        this.handleError(error);
      }
    }

    if (this.auth.wasForcedLogout()) {
      this.showSessionExpiryError = true;
      this.auth.setForcedLogout(false);
    }
  }

  handleError(error: unknown): void {
    this.loading = false;
    this.showError = true;
  }
}
