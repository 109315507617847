<sub-header label="Log Parsers"> </sub-header>

<app-asc-configurable-table
  class="parser-table"
  [dataSource]="dataSource"
  [columns]="columns"
  [sortProps]="sort"
  [pageables]="pageable"
  (sortChange)="sortChange($event)"
  (paginatorChange)="pagChange($event)"
  [columnCells]="[nameTemp, versionTemp, deviceTypeTemp, statusTemp, parsingControllerVersionTemp, actionsTemp]"
>
</app-asc-configurable-table>

<ng-template #nameTemp let-tableElem="tableElem">{{ tableElem.name }}</ng-template>
<ng-template #versionTemp let-tableElem="tableElem">{{ tableElem.version }}</ng-template>
<ng-template #deviceTypeTemp let-tableElem="tableElem">{{ tableElem.deviceType }}</ng-template>
<ng-template #statusTemp let-tableElem="tableElem">{{ tableElem.status }}</ng-template>
<ng-template #parsingControllerVersionTemp let-tableElem="tableElem">{{ tableElem.parsingControllerVersion }}</ng-template>
<ng-template #actionsTemp let-tableElem="tableElem">
  <app-asc-inline-button class="monitor-button" (btnClicked)="goToMonitoringView(tableElem)">Monitor</app-asc-inline-button>
  <app-asc-inline-button
    (btnClicked)="tableElem.status === 'deactivated' ? reactivate(tableElem) : deactivate(tableElem)"
    [appAscTooltip]="
      tableElem.status === 'deactivated'
        ? 'Reactivate parser will resume parsing files'
        : 'Deactivate parser will stop the parser from processing any more files'
    "
  >
    {{ tableElem.status === 'deactivated' ? 'Rectivate' : 'Deactivate' }}
  </app-asc-inline-button>
</ng-template>
