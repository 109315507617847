<app-asc-text-input [control]="deviceIdControl" (keyupEnter)="loadData()" [clear]="true" placeholder="Device ID"></app-asc-text-input>

<app-asc-configurable-table
  class="dlc-count-table"
  [dataSource]="dataSource"
  [pageables]="pageables"
  [sortProps]="sort"
  (sortChange)="sortChange($event)"
  (paginatorChange)="paginatorListener($event)"
  [columns]="tableColumns"
  [columnCells]="[deviceIdTemp, deviceTypeTemp, deviceSnTemp, assignedTemp, unassignedTemp, detailsTemp]"
  [showPaginator]="true"
></app-asc-configurable-table>

<ng-template #deviceIdTemp let-tableElem="tableElem">
  <div [appClipboardCopy]="tableElem.deviceId">{{ tableElem.deviceId }}</div>
</ng-template>

<ng-template #deviceSnTemp let-tableElem="tableElem">
  <div>{{ tableElem.serialNumber }}</div>
</ng-template>

<ng-template #deviceIdTemp let-tableElem="tableElem">
  <div>{{ tableElem.deviceId }}</div>
</ng-template>

<ng-template #deviceTypeTemp let-tableElem="tableElem">
  <div>{{ tableElem.deviceType }}</div>
</ng-template>

<ng-template #assignedTemp let-tableElem="tableElem">
  <div>{{ tableElem.assigned }}</div>
</ng-template>

<ng-template #unassignedTemp let-tableElem="tableElem">
  <div>{{ tableElem.unassigned }}</div>
</ng-template>

<ng-template #detailsTemp let-tableElem="tableElem">
  <app-asc-inline-button (btnClicked)="showDetails(tableElem)">Details</app-asc-inline-button>
</ng-template>
