import { Component } from '@angular/core';
import { ErrorHistoryService } from '../../services/error-history.service';

@Component({
    selector: 'app-error-history',
    templateUrl: './error-history.component.html',
    styleUrls: ['./error-history.component.scss'],
    standalone: false
})
export class ErrorHistoryComponent {
  constructor(public messageHistory: ErrorHistoryService) {}
}
