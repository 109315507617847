<div class="config-table" class="mat-elevation-z2">
  <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows [matSortActive]="initSortCol" [matSortDirection]="initSortDirection">
    @for (column of columns; track column; let i = $index) {
    <ng-container [matColumnDef]="column.name">
      @switch (column.sortable) { @case (true) {
      <mat-header-cell *matHeaderCellDef mat-sort-header [appAscTooltip]="column.tooltip ? column.tooltip : ''">{{ column.header }}</mat-header-cell>
      } @default {
      <mat-header-cell *matHeaderCellDef>
        @if (getHeaderCell(i); as headerCell) {
        <ng-container [ngTemplateOutlet]="headerCell"></ng-container>
        } @if (getHeaderCell(i) === undefined) {
        {{ column.header }}
        }
      </mat-header-cell>
      } }
      <ng-template #normalCell let-header="header"></ng-template>
      <mat-cell *matCellDef="let element">
        <!-- Injects passed templates -->
        @switch (column.name) { @case ('_expandable_col_') { @if (addExpandableRow(element)) {
        <mat-icon>{{ expandedRows[colIdentifier(element)] ? 'expand_less' : 'expand_more' }}</mat-icon>
        } } @default {
        <ng-container [ngTemplateOutlet]="columnCells[expandable ? i - 1 : i]" [ngTemplateOutletContext]="{ tableElem: element }"></ng-container>
        } }
      </mat-cell>
    </ng-container>
    }

    <ng-container matColumnDef="_expandable_row_">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="expand-container" [@detailExpand]="expandedRows[colIdentifier(element)] ? 'expanded' : 'collapsed'">
          <ng-container
            [ngTemplateOutlet]="expandable"
            [ngTemplateOutletContext]="{ tableElem: element, rowExpanded: expandedRows[colIdentifier(element)] }"
          ></ng-container>
        </div>
      </td>
    </ng-container>

    <!-- ROWS -->
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{ hovered: row.hovered, expandableRow: expandable ? true : false }"
      class="table-element-row"
      [class.table-expanded-row]="expandedRows[colIdentifier(row)]"
      (mouseover)="row.hovered = true"
      (mouseout)="row.hovered = false"
      (click)="toggleExpandRow(row, expandedRows[colIdentifier(row)])"
    >
    </mat-row>
    @if (expandable) {
    <tr mat-row *matRowDef="let row; columns: ['_expandable_row_']" class="detail-row"></tr>
    }

    <tr *matNoDataRow>
      @if ((dataSource.isLoading$ | async) === false) { @if (dataSource.isError$ | async) {
      <div ngClass="c-table-info-error" class="c-table-info">
        {{ dataSourceError$ | async }}
      </div>
      } @if ((dataSource.totalSize$ | async) === 0 && (dataSource.isError$ | async) === null) {
      <div class="c-table-info">
        {{ noDataMessage || 'No data available!' }}
      </div>
      } } @if ((dataSource.isLoading$ | async) === true) { @if (dataSource.isLoading$ | async) {
      <div class="spinner-wrapper">
        <mat-spinner class="data-loading-spinner"></mat-spinner>
      </div>
      } }
    </tr>
  </table>

  @if (pageables) {
  <mat-paginator
    [pageSizeOptions]="pageables.pageSizeOptions"
    [pageIndex]="pageables.pageIndex"
    [pageSize]="pageables.pageSize"
    [length]="dataSource.totalSize$ | async"
    showFirstLastButtons
  ></mat-paginator>
  }
</div>
