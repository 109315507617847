<app-asc-dialog-wrapper class=".attach-device-dialog">
  <ng-container dialogTitle>{{ title }}</ng-container>
  <ng-container dialogContent>
    <h3></h3>

    <ng-container
      [ngTemplateOutlet]="selectedDeviceTemp"
      [ngTemplateOutletContext]="{ device: child, header: 'Satellite Device ' + child.deviceType + ' • ' + child.serialNumber }"
    ></ng-container>

    <div class="attach-to-arrow">
      <mat-icon style="width: 60px; height: 60px; font-size: 60px">arrow_downward</mat-icon>
      <p>Attach to</p>
    </div>

    @if (selectedParent$ | async; as parent) {
    <ng-container
      [ngTemplateOutlet]="selectedDeviceTemp"
      [ngTemplateOutletContext]="{ isParent: true, header: 'Parent Device ' + parent.deviceType + ' • ' + parent.serialNumber, device: parent }"
    >
    </ng-container>
    } @if ((selectedParent$ | async) === null && (parents$ | async); as parents) { @for (parent of parents; track parent) {
    <div class="parent-select">
      <p>{{ parent.deviceType }} • {{ parent.serialNumber }}</p>
      <mat-checkbox [checked]="(selectedParent$ | async)?.id === parent.id" (change)="selectParent(parent)"></mat-checkbox>
    </div>
    } }

    <div class="search-input" style="margin-top: 24px">
      <app-asc-text-input
        placeholder="Parents serial number"
        label="Parent Device"
        (keyupEnter)="searchDevice($event)"
        [control]="searchControl"
      ></app-asc-text-input>
      <app-asc-icon-button size="medium" class="search-icon-button" (btnClicked)="searchDevice(searchControl.value)">search</app-asc-icon-button>
    </div>
    @if (loading$ | async) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    } @if (notFound) {
    <mat-error style="text-align: center">No device found with serial number {{ searchControl.value }}</mat-error>
    }
  </ng-container>
  <app-asc-button type="primary" dialogActionButton [disabled]="(selectedParent$ | async) === null" (btnClicked)="actionButtonClicked()">Attach</app-asc-button>
  <app-asc-button type="cancel" dialogCancelButton (btnClicked)="closeButtonClicked()">Cancel</app-asc-button>
</app-asc-dialog-wrapper>

<ng-template #selectedDeviceTemp let-device="device" let-header="header" let-isParent="isParent">
  <div class="device-card">
    <h4>{{ header }}</h4>
    <div class="device-details">
      <div class="details-col">
        <div>ID:</div>
        <div>Hardware ID:</div>
      </div>
      <div class="details-col">
        <div>{{ device.id }}</div>
        <div>{{ device.hardwareId || 'N/A' }}</div>
      </div>
    </div>
    @if (isParent) {
    <mat-icon (click)="removeParentDevice()" class="remove-parent-icon">close</mat-icon>
    }
  </div>
</ng-template>
