import { SortDirection } from '@angular/material/sort';
import { catchError, finalize, map, of } from 'rxjs';
import { DataSourceBaseImpl } from 'src/app/shared/interfaces/DataSourceBase';
import { SoftwareManagementService } from '../services/software-management.service';
import { DownloadableContentCountPerDevice } from './DownloadableContentPerDevice';

export class SoftwarePerDeviceDataSource extends DataSourceBaseImpl<DownloadableContentCountPerDevice> {
  constructor(private apiService: SoftwareManagementService) {
    super();
  }

  getData(deviceId: string, pageSize: number, pageIndex: number, sortColumn: string, sortDirection: SortDirection) {
    this.loadingSubject.next(true);
    this.errorSubject.next(null);
    this.totalSizeSubject.next(0);
    this.dataSubject.next([]);
    this.apiService
      .getDownloadableContentCountPerDevice(pageSize, pageIndex, sortColumn, sortDirection, deviceId)
      .pipe(
        map((dlcCount) => {
          return dlcCount;
        }),
        catchError((err) => {
          this.errorSubject.next(err);
          return of();
        }),
        finalize(() => {
          this.loadingSubject.next(false);
        })
      )
      .subscribe((dlcCount) => {
        if (dlcCount.content) {
          this.dataSubject.next(dlcCount.content);
        }
        this.totalSizeSubject.next(dlcCount.totalSize);
      });
  }
}
