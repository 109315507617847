import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ToastsService } from 'src/app/error-handling/services/toasts.service';
import { Parser } from 'src/app/logging/interfaces/Parser';
import { ConfigPageEvent } from 'src/app/shared/components/asc-configurable-table/asc-configurable-table.component';
import { ConfigureableTableColumn } from 'src/app/shared/interfaces/ConfigureableTableColumn';
import { ParserListDataSource } from '../../interfaces/ParserListDataSource';
import { LoggingService } from '../../services/logging.service';

@Component({
  selector: 'app-log-parser-management',
  templateUrl: './log-parser-management.component.html',
  styleUrls: ['./log-parser-management.component.scss'],
  standalone: false,
})
export class LogParserManagementComponent implements OnInit {
  constructor(private router: Router, private apiService: LoggingService, public dialog: MatDialog, private toasts: ToastsService) {}

  columns: ConfigureableTableColumn<Parser>[] = [
    {
      name: 'name',
      header: 'Name',
      sortable: true,
    },
    {
      name: 'version',
      header: 'Version',
      sortable: true,
    },
    {
      name: 'deviceType',
      header: 'Device Type',
      sortable: true,
    },
    {
      name: 'status',
      header: 'Status',
      sortable: true,
    },
    {
      name: 'parsingControllerVersion',
      header: 'Parsing Controller Version',
      sortable: true,
    },
    {
      name: 'actions',
      header: 'Actions',
      sortable: false,
    },
  ];

  sort: Sort = {
    active: '',
    direction: 'asc',
  };
  pageable: ConfigPageEvent = {
    pageIndex: 0,
    pageSize: 10,
    pageSizeOptions: [10, 20, 50],
  };

  dataSource: ParserListDataSource;

  ngOnInit(): void {
    this.dataSource = new ParserListDataSource(this.apiService);

    this.loadParsers();
  }

  loadParsers() {
    this.dataSource.loadParsers(this.pageable.pageSize, this.pageable.pageIndex, this.sort.active || '', this.sort.direction || 'asc');
  }

  sortChange(sort: Sort) {
    this.sort = sort;
    this.loadParsers();
  }
  pagChange(pag: PageEvent) {
    this.pageable = {
      ...this.pageable,
      pageIndex: pag.pageIndex,
      pageSize: pag.pageSize,
    };
    this.loadParsers();
  }

  goToMonitoringView(parser: Parser) {
    this.router.navigate(['logparsermonitoring', parser.parserId]);
  }

  deactivate(parser: Parser) {
    this.apiService.deactivateParser(parser.parserId).subscribe({
      next: () => this.loadParsers(),
      error: (error) => this.toasts.raise({ message: `Failed to deactivate parser ${parser.name}`, title: 'Deactivate Parser', error: error }, 'ERROR'),
    });
  }

  reactivate(parser: Parser) {
    this.apiService.reactivateParser(parser.parserId).subscribe({
      next: () => this.loadParsers(),
      error: (error) => this.toasts.raise({ message: `Failed tor eactivate parser ${parser.name}`, title: 'Reactivate Parser', error: error }, 'ERROR'),
    });
  }

  goHome(): void {
    this.router.navigate(['devicelist']);
  }
}
