import { Component } from '@angular/core';
import { AscTabbedPageComponent } from '../../../shared/components/asc-tabbed-page/asc-tabbed-page.component';

@Component({
    selector: 'app-failed-job-management-tabs',
    templateUrl: './failed-job-management-tabs.component.html',
    styleUrls: ['./failed-job-management-tabs.component.scss'],
    standalone: false
})
export class FailedJobManagementTabsComponent extends AscTabbedPageComponent {}
