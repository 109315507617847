<mat-expansion-panel class="c-expansion-panel mat-elevation-z0" [togglePosition]="'before'">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <span matTooltip="Device Type"> {{ device.deviceType }} </span> <span class="bullet">•</span>
      <span matTooltip="Serial Number"> {{ device.serialNumber }}</span>
    </mat-panel-title>
    <mat-panel-description>
      <div class="device-status">
        @if (device.parentDeviceId) {
          <mat-icon style="margin-right: 10px" matTooltip="Satellite Device">satellite_alt</mat-icon>
        }

        @if (device | deviceOnlineOffline; as offOn) {
          <mat-icon [ngStyle]="{ color: offOn.color, marginRight: '10px' }" [matTooltip]="offOn.label">{{ offOn.icon }}</mat-icon>
        }
        {{ device.status }}
      </div>
      <div class="device-actions">
        <app-device-actions [device]="device"></app-device-actions>
      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="device-details-container">
    @if (device | deviceId; as idProps) {
      <div class="details-col">
        <div>ID:</div>
        @if (idProps.hardwareId) {
          <div>Hardware ID:</div>
        }
        @if (idProps.regIdNeeded) {
          <div>Registration ID:</div>
        }
      </div>
      <div class="details-col">
        <div [appClipboardCopy]="idProps.id">{{ idProps.id }}</div>
        @if (idProps.hardwareId) {
          <div [appClipboardCopy]="idProps.hardwareId">{{ idProps.hardwareId }}</div>
        }
        @if (idProps.regIdNeeded) {
          <div [appClipboardCopy]="idProps.registrationId">
            {{ idProps.registrationId }} {{ idProps.expired ? '(expired)' : '' }}
          </div>
        }
      </div>
    }

    <div class="vertical-divider"></div>

    <div class="details-col">
      <p>Note:</p>
    </div>
    <div class="details-col">
      <p>{{ device.note || 'N/A' }}</p>
    </div>
    @if (device.parentDeviceId) {
      <div class="vertical-divider"></div>
      <div class="details-col button-parent">
        <app-asc-inline-button cType="secondary" (btnClicked)="goToParent(device.parentDeviceId)">Parent</app-asc-inline-button>
      </div>
    }
    <div class="vertical-divider"></div>
    <div class="details-col">
      <div>Update DHS:</div>
      <div>Allows Remote Access:</div>
      <div>Training Device:</div>
    </div>
    <div class="details-col">
      <mat-icon [ngStyle]="{ color: device.updateDHS ? 'green' : '#236fb8' }">{{ device.updateDHS ? 'check_circle' : 'cancel' }}</mat-icon>
      <mat-icon [ngStyle]="{ color: device.allowRemoteDesktopAccess ? 'green' : '#236fb8' }">{{
        device.allowRemoteDesktopAccess ? 'check_circle' : 'cancel'
      }}</mat-icon>
      <mat-icon [ngStyle]="{ color: device.trainingDevice ? 'green' : '#236fb8' }">{{ device.trainingDevice ? 'check_circle' : 'cancel' }}</mat-icon>
    </div>
  </div>
</mat-expansion-panel>

<mat-menu #actionsMenu="matMenu">
  <ng-template matMenuContent let-device="device">
    @if (device | deviceActions; as actions) {
      <h4 class="menu-header">Action{{ actions.length > 1 ? 's' : '' }}</h4>
      @for (action of actions; track action) {
        <button mat-menu-item>
          <mat-icon>{{ action.matIcon }}</mat-icon
            >{{ action.actionName }}
          </button>
        }
        <mat-divider style="margin-bottom: 6px"></mat-divider>
      }
    </ng-template>
  </mat-menu>
