import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './additional-pages/components/page-not-found/page-not-found.component';
import { LoginComponent } from './authentication/components/login/login.component';
import { AuthGuard } from './authentication/guards/auth.guard';
import { LogParserManagementComponent } from './logging/components/log-parser-management/log-parser-management.component';
import { LogParserMonitoringComponent } from './logging/components/log-parser-monitoring/log-parser-monitoring.component';
import { OngoingUploadsComponent } from './ongoing-uploads/components/ongoing-uploads/ongoing-uploads.component';

import { AboutPageComponent } from './additional-pages/components/about-page/about-page.component';
import { OktaLoginCallbackComponent } from './authentication/components/okta-login-callback/okta-login-callback.component';
import { DeliverDownloadableContentComponent } from './device/components/deliver-downloadable-content/deliver-downloadable-content.component';
import { DeviceDetailsComponent } from './device/components/device-details/device-details.component';
import { DeviceHealthComponent } from './device/components/device-health/device-health.component';
import { DeviceLifecycleComponent } from './device/components/device-lifecycle/device-lifecycle.component';
import { DeviceTypeComponent } from './device/components/device-type/device-type.component';
import { FailedJobsTabComponent } from './failed-job-management/components/failed-jobs-tab/failed-jobs-tab.component';
import { UserActivityLogComponent } from './logging/components/user-activity-log/user-activity-log.component';
import { SoftwareManagementComponent } from './software-management/components/software-management/software-management.component';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LoginComponent,
  },
  { path: 'login', component: LoginComponent },
  { path: 'login/callback', component: OktaLoginCallbackComponent },
  {
    path: 'devicelist',
    component: DeviceLifecycleComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'devicedetails/:deviceId',
    component: DeviceDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'devicehealth/:deviceId',
    component: DeviceHealthComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'deliverDownloadableContent/:fileUid',
    component: DeliverDownloadableContentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'devicetypemanagement',
    component: DeviceTypeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'ongoinguploads',
    component: OngoingUploadsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'logparsers',
    component: LogParserManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'softwarelibrary',
    component: SoftwareManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'logparsermonitoring/:parserId',
    component: LogParserMonitoringComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'about',
    component: AboutPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pagenotfound',
    component: PageNotFoundComponent,
  },
  {
    path: 'failedjobs',
    component: FailedJobsTabComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'user-activities',
    component: UserActivityLogComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: '/pagenotfound',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
