import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ToastrService } from 'ngx-toastr';
import { ErrorHandlerService } from '../../../error-handling/services/error-handler.service';
import { Device } from '../../interfaces/Device';
import { DeviceApiService } from '../../services/device-api.service';

@Component({
    selector: 'app-device-remote-desktop',
    templateUrl: './device-remote-desktop.component.html',
    styleUrls: ['./device-remote-desktop.component.scss'],
    standalone: false
})
export class DeviceRemoteDesktopComponent {
  @Input() device: Device;
  @Output() deviceChange = new EventEmitter<Device>();

  constructor(private deviceService: DeviceApiService, private errorHandlerService: ErrorHandlerService, private toastr: ToastrService) {}

  toggleAllowRemoteDesktopAccess(event: MatCheckboxChange): void {
    this.device.allowRemoteDesktopAccess = event.checked;
    this.deviceService.updateDevice(this.device).subscribe({
      next: () => this.toastr.success(`Remote desktop access is now ${this.device.allowRemoteDesktopAccess ? 'enabled' : 'disabled'}`),
      error: (error) => this.errorHandlerService.handleHttpError(error, 'Could not update device'),
    });
    this.deviceChange.emit(this.device);
  }
}
