import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable, Subscription, catchError, map, of, shareReplay } from 'rxjs';
import { AscAutocompleteProperties } from 'src/app/asc-forms/interfaces/AscAutocompleteProperties';
import { ToastsService } from 'src/app/error-handling/services/toasts.service';
import { AppStorageService } from 'src/app/shared/app-storage.service';
import { ConfigPageEvent } from 'src/app/shared/components/asc-configurable-table/asc-configurable-table.component';
import { AscConfirmDialogComponent } from 'src/app/shared/components/asc-confirm-dialog/asc-confirm-dialog.component';
import { AscConfirmDialogData } from 'src/app/shared/interfaces/AscConfirmDialogData';
import { ConfigureableTableColumn } from 'src/app/shared/interfaces/ConfigureableTableColumn';
import { DialogResponse } from 'src/app/shared/interfaces/DialogResponse';
import { StorageOperations } from 'src/app/shared/interfaces/StorageOperations';
import { SharedApiService } from 'src/app/shared/services/shared-api.service';
import { DeviceType } from '../../../device/interfaces/DeviceType';
import {
  ActivationStatus,
  DownloadableContent,
  downloadPolicyDropDown,
  executionPolicyDropDown, SignatureValidation,
} from '../../../shared/interfaces/DownloadableContent';
import { DownloadableContentDataSource } from '../../interfaces/DownloadableContentDataSource';
import { FileSizePipe } from '../../pipes/filesize.pipe';
import { SoftwareManagementService } from '../../services/software-management.service';
import { ShowDetailsDialogComponent } from '../show-details-dialog/show-details-dialog.component';
import { SoftwareLibraryUploadDialogComponent } from '../software-library-upload-dialog/software-library-upload-dialog.component';

@Component({
    selector: 'app-software-library',
    templateUrl: './software-library.component.html',
    styleUrls: ['./software-library.component.scss'],
    providers: [FileSizePipe],
    standalone: false
})
export class SoftwareLibraryComponent implements OnInit, OnDestroy, StorageOperations {
  tableData: DownloadableContentDataSource;
  pageable: ConfigPageEvent;
  sort: Sort;
  tableColumns: ConfigureableTableColumn<DownloadableContent>[] = [
    {
      sortable: true,
      name: 'displayName',
      header: 'Display Name',
    },
    {
      sortable: true,
      name: 'fileName',
      header: 'File Name',
    },
    {
      sortable: true,
      name: 'fileType',
      header: 'Type',
    },
    {
      sortable: false,
      name: 'fileVersion',
      header: 'Version',
    },
    {
      sortable: true,
      name: 'fileSize',
      header: 'Size',
    },
    {
      sortable: false,
      name: 'status',
      header: 'Status',
    },
    {
      sortable: false,
      name: 'actions',
      header: 'Actions',
    },
  ];

  deviceTypeSearchProps: AscAutocompleteProperties<DeviceType> = {
    humanizeOption: (dt: DeviceType) => dt.deviceType,
    filterPredicate: (val: DeviceType, searchInput: string) => val.deviceType.toLowerCase().startsWith(searchInput.toLowerCase()),
    equals: (a: DeviceType, b: DeviceType) => a.deviceType === b.deviceType,
    placeholder: 'Device Type...',
  };

  deviceTypeControl: FormControl<DeviceType> = new FormControl();
  deviceTypes$: Observable<DeviceType[]>;
  controlSub$: Subscription;

  constructor(
    private router: Router,
    private apiService: SoftwareManagementService,
    private sharedApiService: SharedApiService,
    private titleService: Title,
    public dialog: MatDialog,
    private fileSizePipe: FileSizePipe,
    private store: AppStorageService,
    private toasts: ToastsService
  ) {}
  ngOnInit(): void {
    this.tableData = new DownloadableContentDataSource(this.apiService);
    this.getStorageData();
    this.getDeviceTypes();
    window.onbeforeunload = () => this.saveStorageData();
    this.loadData();
  }
  loadData() {
    this.tableData.loadDownloadableContent(
      this.pageable.pageSize,
      this.pageable.pageIndex,
      this.sort.active,
      this.sort.direction,
      this.deviceTypeControl.value?.deviceType || undefined
    );
  }
  getDeviceTypes() {
    this.deviceTypes$ = this.sharedApiService.getDeviceTypeList().pipe(
      shareReplay(),
      map((resp) => resp.content || []),
      map((dts) => dts.sort((a, b) => (a.deviceType.toLowerCase() > b.deviceType.toLowerCase() ? 1 : -1))),
      catchError((err) => {
        this.toasts.raise({ message: 'Can not get device types', error: err, title: 'Device Types' }, 'ERROR');
        return of();
      })
    );
    this.controlSub$ = this.deviceTypeControl.valueChanges.subscribe(() => {
      this.loadData();
    });
  }

  getStorageData(): void {
    this.sort = {
      active: this.store.getValue('software_library_sort_column') || '',
      direction: this.store.getValue('software_library_sort_direction') || '',
    };
    this.pageable = {
      pageSize: this.store.getValue('software_library_pagination_pagesize') || 10,
      pageIndex: 0,
      pageSizeOptions: [10, 20, 50],
    };
  }
  saveStorageData(): void {
    this.store.setValue('software_library_sort_column', this.sort.active, 'string', true);
    this.store.setValue('software_library_sort_direction', this.sort.direction, 'string', true);
    this.store.setValue('software_library_pagination_pagesize', this.pageable.pageSize, 'number', true);
  }
  onPagChange(evt: PageEvent) {
    this.pageable = {
      ...this.pageable,
      pageIndex: evt.pageIndex,
      pageSize: evt.pageSize,
    };
    this.loadData();
  }
  onSortChange(evt: Sort) {
    this.sort = {
      direction: evt.direction,
      active: evt.direction ? evt.active : '',
    };
    this.loadData();
  }
  ActivationStatus = ActivationStatus;
  SignatureValidation = SignatureValidation;

  goHome(): void {
    this.router.navigate(['devicelist']);
  }

  openUploadFileDialog(): void {
    const dialogRefFileUpload = this.dialog.open(SoftwareLibraryUploadDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '70%',
      width: '80%',
      data: {
        editMode: false,
        downloadableContent: null,
      },
    });

    dialogRefFileUpload.afterClosed().subscribe((result) => {
      if (result === 'SUCCESS') {
        this.ngOnInit(); // refresh the content of the table upon successful deletion
      }
    });
  }

  openEditFileDialog(downloadableContent: DownloadableContent): void {
    const dialogRefFileUpload = this.dialog.open(SoftwareLibraryUploadDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '70%',
      width: '80%',
      data: {
        editMode: true,
        downloadableContent: downloadableContent,
      },
    });

    dialogRefFileUpload.afterClosed().subscribe((result) => {
      if (result === 'SUCCESS') {
        this.ngOnInit(); // refresh the content of the table upon successful deletion
      }
    });
  }

  activateAction(dlc: DownloadableContent) {
    if (!dlc.fileUuid) {
      this.toasts.raise(
        {
          message: `Can not activate downloadable content ${dlc.displayName} since no file UUID was provided.`,
          title: 'Ativate Downloadable Content',
        },
        'ERROR'
      );
      return;
    }
    this.apiService
      .patchDownloadableContent(dlc.fileUuid, ActivationStatus.ACTIVE)
      .pipe(
        catchError((err) => {
          this.toasts.raise(
            { title: 'Activate Downloadable Content', message: `Can not activate downloadable content ${dlc.displayName}`, error: err },
            'ERROR'
          );
          return of();
        })
      )
      .subscribe(() => {
        this.loadData();
      });
  }

  deactivateAction(dlc: DownloadableContent) {
    if (!dlc.fileUuid) {
      this.toasts.raise(
        {
          message: `Can not deactivate downloadable content ${dlc.displayName} since no file UUID was provided.`,
          title: 'Ativate Downloadable Content',
        },
        'ERROR'
      );
      return;
    }
    this.apiService
      .patchDownloadableContent(dlc.fileUuid, ActivationStatus.DEACTIVATED)
      .pipe(
        catchError((err) => {
          this.toasts.raise(
            { title: 'Activate Downloadable Content', message: `Can not deactivate downloadable content ${dlc.displayName}`, error: err },
            'ERROR'
          );
          return of();
        })
      )
      .subscribe(() => {
        this.loadData();
      });
  }

  deliverAction(element: any): void {
    this.router.navigate(['deliverDownloadableContent', element.fileUuid]);
  }

  detailsButtonClick(downloadableContent: DownloadableContent): void {
    this.dialog.open(ShowDetailsDialogComponent, {
      minWidth: '900px',
      height: '600px',
      data: {
        groups: [
          {
            name: 'General',
            keyValuePairs: {
              'Display Name': downloadableContent.displayName,
              Summary: downloadableContent.summary,
              'File name': downloadableContent.fileName,
              'File type': downloadableContent.fileType,
              'File size': this.fileSizePipe.transform(downloadableContent.fileSize),
              'Download Policy': downloadPolicyDropDown.find((policy) => policy.value == downloadableContent.downloadPolicy)?.viewValue,
              'Execution Policy': executionPolicyDropDown.find((policy) => policy.value == downloadableContent.executionPolicy)?.viewValue,
              Checksum: downloadableContent.checksum,
            },
          },
          {
            name: 'Device types',
            activeSortColumn: 'key',
            sortDirection: 'asc',
            keyValuePairs: downloadableContent.deviceTypes,
          },
          {
            name: 'Applicable criteria',
            activeSortColumn: 'key',
            sortDirection: 'asc',
            keyValuePairs: downloadableContent.applicabilityCriteria,
          },
        ],
      },
    });
  }

  openDeleteConfirmDialog(downloadableContent: DownloadableContent): void {
    const dialogConfig: MatDialogConfig<AscConfirmDialogData<unknown, unknown>> = {
      data: {
        title: 'Delete Downloadable Content',
        message: `Do you really want to delete downloadable content ${downloadableContent.displayName}?`,
        dialogType: 'warning',
        actionLabel: 'Delete',
        action: {
          call: () => this.apiService.deleteDownloadableContent(downloadableContent),
          errorHandler: () => `Could not delete downloadable content ${downloadableContent.displayName}`,
          successHandler: () => `Deleted downloadable content ${downloadableContent.displayName}`,
        },
      },
    };
    const dialogRef = this.dialog.open(AscConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((resp: Partial<DialogResponse>) => {
      if (resp.status === 'SUCCESS') {
        this.loadData();
      }
    });
  }
  ngOnDestroy(): void {
    this.saveStorageData();
    if (this.controlSub$) this.controlSub$.unsubscribe();
  }
}
