import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import moment from 'moment-timezone';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthApiService } from './auth-api.service';

@Injectable()
export class AuthService {
  constructor(private router: Router, private authApi: AuthApiService) {}

  private autoLogoutId = 0;

  public async oktaLogin() {
    const resp = await lastValueFrom(this.authApi.getAuthUrl(environment.oktaConfig.callbackUrl));
    if (resp.authUrl) {
      window.location.href = resp.authUrl;
    } else {
      throw 'Authentication URL not received';
    }
  }

  public async oktaLoginCallback(code: string, state: string): Promise<void> {
    const response = await lastValueFrom(this.authApi.createTokenFromCode(code, state, environment.oktaConfig.callbackUrl));
    if (response.accessToken) {
      this.setExpirationIn(response.expiresIn);
      this.setToken(response.accessToken);
      this.setIdToken(response.idToken);
    } else {
      throw 'Did not receive access token';
    }
  }

  public oktaLogout() {
    window.location.href = encodeURI(
      `${environment.oktaConfig.baseUrl}/v1/logout` +
        `?token=${this.getToken()}` +
        `&id_token_hint=${this.getIdToken()}` +
        `&client_id=${environment.oktaConfig.clientId}` +
        `&post_logout_redirect_uri=${environment.oktaConfig.logoutRedirectUrl}` +
        `&state=logged-out`
    );
  }

  public handleLogoutRedirect(params: Params) {
    if (params.state === 'logged-out') {
      this.clearAuthSession();
    }
  }

  public async afterLogin(): Promise<void> {
    await this.router.navigate(['devicelist']);
  }

  public isAuthenticated(): boolean {
    return this.getExpiration().isAfter(moment());
  }
  public getToken(): string | null {
    return sessionStorage.getItem('jwtToken');
  }
  public setToken(accessToken: string): void {
    sessionStorage.setItem('jwtToken', accessToken);
  }
  public getIdToken(): string | null {
    return sessionStorage.getItem('idToken');
  }
  public setIdToken(idToken: string): void {
    sessionStorage.setItem('idToken', idToken);
  }

  public getExpiration(): moment.Moment {
    const expiresAt = JSON.parse(sessionStorage.getItem('expires_at') || '{}');
    return moment(expiresAt);
  }
  public setExpirationIn(expiresInSeconds: number): void {
    const expiresAt = moment().add(expiresInSeconds, 'second');
    sessionStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
    this.setAutoLogout(expiresInSeconds * 1000);
  }
  public getUsername(): string {
    return sessionStorage.getItem('username') || '';
  }
  public setUsername(username: string): void {
    sessionStorage.setItem('username', username);
  }
  private clearAuthSession(): void {
    sessionStorage.removeItem('jwtToken');
    sessionStorage.removeItem('idToken');
    sessionStorage.removeItem('expires_at');
    sessionStorage.removeItem('username');
  }

  public wasForcedLogout(): boolean {
    return sessionStorage.getItem('isForcedLogOut')?.toLowerCase() === 'true';
  }
  public setForcedLogout(forcedLogout: boolean): void {
    sessionStorage.setItem('isForcedLogOut', String(forcedLogout));
  }

  // this method is executed on first login and has a timeout function which logs the user out after the token expires
  private setAutoLogout(expirationTime: number): void {
    this.clearAutoLogout();
    this.autoLogoutId = window.setTimeout(async () => {
      this.setForcedLogout(true);
      this.oktaLogout();
    }, expirationTime);
  }

  private clearAutoLogout(): void {
    if (this.autoLogoutId > 0) {
      window.clearTimeout(this.autoLogoutId);
      this.autoLogoutId = 0;
    }
  }
}
