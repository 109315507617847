import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-asc-asc-tabbed-page',
    template: '',
    styles: [],
    standalone: false
})
export class AscTabbedPageComponent implements OnDestroy {
  queryParamSub$ = new Subscription();
  protected selectedTabIndex: number;
  protected selectedTabParamName = 't';
  constructor(protected activeRoute: ActivatedRoute, protected router: Router) {
    this.queryParamSub$ = this.activeRoute.queryParamMap.subscribe((params) => {
      this.selectedTabIndex = parseInt(params.get(this.selectedTabParamName) || '0');
    });
  }

  onTabChanged() {
    this.router.navigate([], {
      relativeTo: this.activeRoute,
      queryParams: { [this.selectedTabParamName]: this.selectedTabIndex },
      queryParamsHandling: 'merge',
    });
  }

  ngOnDestroy(): void {
    this.queryParamSub$.unsubscribe();
  }
}
