import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';
import moment, { Moment } from 'moment';
import { Observable, shareReplay } from 'rxjs';
import { AscAutocompleteProperties } from 'src/app/asc-forms/interfaces/AscAutocompleteProperties';
import { DateRangePickerResponse, DateRangeRelativeResponse } from 'src/app/asc-forms/interfaces/DateRangePickerResponse';
import { DateRangeUtils } from 'src/app/asc-forms/interfaces/DateRangeUtils';
import { UserActivity } from 'src/app/logging/interfaces/UserActivity';
import { LoggingService } from 'src/app/logging/services/logging.service';
import { AppStorageService } from 'src/app/shared/app-storage.service';
import { ConfigureableTableColumn } from 'src/app/shared/interfaces/ConfigureableTableColumn';
import { StorageOperations } from 'src/app/shared/interfaces/StorageOperations';
import { Device } from '../../interfaces/Device';
import { UserActivityDataSource } from './../../../logging/interfaces/UserActivityDataSource';

@Component({
    selector: 'app-device-user-activity-log',
    templateUrl: './device-user-activity-log.component.html',
    styleUrls: ['./device-user-activity-log.component.scss'],
    standalone: false
})
export class DeviceUserActivityLogComponent implements StorageOperations, OnDestroy {
  @Input() device: Device;
  dataSource: UserActivityDataSource;
  activityTypes$: Observable<string[]>;
  activityTypeConfig: AscAutocompleteProperties<string> = {
    humanizeOption: (val) => val,
    equals: (a, b) => a === b,
    filterPredicate: (val, input) => val.toLowerCase().startsWith(input.toLowerCase()),
    placeholder: 'Activity Type',
  };
  selectedRange: { label: string; seconds: number } | null = null;
  activityTypeControl = new FormControl<string | null>(null);

  tableColumns: ConfigureableTableColumn<UserActivity>[] = [
    {
      sortable: true,
      name: 'timestamp',
      header: 'Timestamp',
    },
    {
      sortable: true,
      name: 'user',
      header: 'User',
    },
    {
      sortable: false,
      name: 'activityType',
      header: 'Activity Type',
    },
    {
      sortable: false,
      name: 'activityDetails',
      header: 'Activity Details',
    },
  ];
  dateRange: DateRange<Moment | null> = new DateRange(null, null);
  relativeRange: DateRangeRelativeResponse | null = null;
  rangeButtonLabel: string;
  datePickerIndex = 0;
  timeRangeControl = new FormControl('');
  dateRangeUtils: DateRangeUtils;
  noDataMessage: string | undefined = 'Select a time range and start the search.';

  constructor(private loggingService: LoggingService, private store: AppStorageService, private datePipe: DatePipe) {
    this.dataSource = new UserActivityDataSource(this.loggingService);
    this.activityTypes$ = this.loggingService.getUserActivityTypes().pipe(shareReplay());
    this.dateRangeUtils = new DateRangeUtils(this.datePipe);
    this.getStorageData();
    window.onbeforeunload = () => this.saveStorageData();
  }
  loadData(fromDate: Date | null, toDate: Date | null, rangeSeconds: number | null, activityType: string | null) {
    this.noDataMessage = undefined;
    this.dataSource.loadUserActivity(activityType, null, this.device.id, fromDate, toDate, rangeSeconds, 1000);
  }
  getStorageData(): void {
    const index = this.store.getValue<number>('device-user-activity-log_date-range-picker-index');
    if (index) this.datePickerIndex = index;
    const startDateStr = this.store.getValue<string>('device-user-activity-log_date-range_start');
    const endDateStr = this.store.getValue<string>('device-user-activity-log_date-range_end');
    if (startDateStr && endDateStr) {
      this.dateRange = new DateRange(moment(new Date(startDateStr)), moment(new Date(endDateStr)));
      if (this.datePickerIndex === 0) this.timeRangeControl.setValue(this.dateRangeUtils.getAbsoluteLabel(this.dateRange));
    }
    const relativeRange = this.store.getValue<DateRangeRelativeResponse>('device-user-activity-log_date-range_relative');
    if (relativeRange) {
      this.relativeRange = relativeRange;
      if (this.datePickerIndex === 1) this.timeRangeControl.setValue(this.dateRangeUtils.getRelativeLabel(this.relativeRange));
    }
    this.activityTypeControl.setValue(this.store.getValue('device-user-activity-log_activity-type') || null);
  }
  saveStorageData(): void {
    this.store.setValue('device-user-activity-log_date-range-picker-index', this.datePickerIndex, 'number', true);
    if (this.dateRange.end && this.dateRange.start) {
      this.store.setValue('device-user-activity-log_date-range_start', this.dateRange.start.toDate().toISOString(), 'string', true);
      this.store.setValue('device-user-activity-log_date-range_end', this.dateRange.end.toDate().toISOString(), 'string', true);
    }
    if (this.relativeRange) {
      this.store.setValue('device-user-activity-log_date-range_relative', this.relativeRange, 'object', true);
    }
    if (this.activityTypeControl.value) {
      this.store.setValue('device-user-activity-log_activity-type', this.activityTypeControl.value, 'string', true);
    }
  }

  selectRange(range: { label: string; seconds: number }) {
    this.selectedRange = range;
    this.dataSource.loadUserActivity(this.activityTypeControl.value, null, this.device.id, null, null, range.seconds, 1000);
  }
  enableSearchButton() {
    return (this.datePickerIndex === 0 && this.dateRange.start && this.dateRange.end) || (this.datePickerIndex === 1 && this.relativeRange);
  }

  getRelativeRange(relative: DateRangePickerResponse<'relative'>) {
    this.relativeRange = relative.range;
    this.timeRangeControl.setValue(this.dateRangeUtils.getRelativeLabel(this.relativeRange));
    this.datePickerIndex = 1;
  }
  getAbsoluteRange(absolute: DateRangePickerResponse<'absolute'>) {
    this.dateRange = absolute.range;
    this.timeRangeControl.setValue(this.dateRangeUtils.getAbsoluteLabel(this.dateRange));
    this.datePickerIndex = 0;
  }
  startSearch() {
    if (this.datePickerIndex === 0 && this.dateRange.start && this.dateRange.end)
      this.loadData(this.dateRange.start.toDate(), this.dateRange.end.toDate(), null, this.activityTypeControl.value);
    if (this.datePickerIndex === 1 && this.relativeRange) this.loadData(null, null, this.relativeRange.seconds, this.activityTypeControl.value);
  }
  ngOnDestroy(): void {
    this.saveStorageData();
  }
}
