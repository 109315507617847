import { KeyValuePipe } from '@angular/common';
import { Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-show-details-dialog',
    templateUrl: './show-details-dialog.component.html',
    styleUrls: ['./show-details-dialog.component.scss'],
    providers: [KeyValuePipe],
    standalone: false
})
export class ShowDetailsDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ShowDetailsDialogComponent>,
    private keyValuePipe: KeyValuePipe
  ) {}

  displayedColumns: string[] = ['key', 'value'];

  dataSources: MatTableDataSource<any>[] = [];

  @ViewChildren(MatSort) sorts = new QueryList<MatSort>();

  ngOnInit(): void {
    this.data.groups.forEach((group: any, i: number) => {
      this.dataSources[i] = new MatTableDataSource(this.keyValuePipe.transform(group.keyValuePairs));
    });
    setTimeout(() => {
      this.dataSources.forEach((dataSource: any, index: number) => {
        const sort = this.sorts.find((_, i: number) => i == index);
        if (sort != undefined) {
          sort.active = this.data.groups[index].activeSortColumn;
          sort.direction = this.data.groups[index].sortDirection;
          dataSource.sort = sort;
        }
      });
    });
  }
}
