@if (properties.label) {
  <mat-label>{{ properties.label }}</mat-label>
}
<mat-form-field appearance="outline">
  <mat-select>
    <mat-option>
      <app-asc-text-input [control]="filterInputFormControl"></app-asc-text-input>
    </mat-option>
  </mat-select>
</mat-form-field>
