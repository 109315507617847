import { Pipe, PipeTransform } from '@angular/core';
import { Device, DeviceStatus } from '../interfaces/Device';

type DeviceIdsDispalyable = {
  regIdNeeded: boolean;
  id: string;
  registrationId: string;
  expired: boolean;
  hardwareId: string;
  parentId: string;
  trainingDevice: boolean;
};

@Pipe({
    name: 'deviceId',
    standalone: false
})
export class DeviceIdPipe implements PipeTransform {
  transform(device: Device, ...args: unknown[]): DeviceIdsDispalyable {
    if (!(device.status === DeviceStatus.REGISTRATION_ID_ISSUED || device.status === DeviceStatus.NOT_REGISTERED)) {
      return {
        regIdNeeded: false,
        id: device.id,
        registrationId: device.registrationId,
        expired: true,
        hardwareId: device.hardwareId,
        parentId: device.parentDeviceId,
        trainingDevice: device.trainingDevice,
      };
    }
    if (!device.registrationValidUntil) {
      return {
        regIdNeeded: true,
        id: device.id,
        registrationId: device.registrationId,
        expired: true,
        hardwareId: device.hardwareId,
        parentId: device.parentDeviceId,
        trainingDevice: device.trainingDevice,
      };
    }
    const validUntil = new Date(device.registrationValidUntil);
    const now = new Date();

    return {
      regIdNeeded: true,
      id: device.id,
      registrationId: device.registrationId,
      expired: validUntil < now,
      hardwareId: device.hardwareId,
      parentId: device.parentDeviceId,
      trainingDevice: device.trainingDevice,
    };
  }
}
