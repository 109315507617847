<div id="device-details-wrapper">
  <app-asc-inline-button (btnClicked)="toDeviceList()"> <mat-icon>arrow_back</mat-icon> Device List </app-asc-inline-button>
  @if (device$ | async; as device) {
    <app-device-details-card [device]="device"></app-device-details-card>
    <div class="">
      <mat-tab-group class="device-details-tabgroup" [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChanged()">
        <mat-tab label="File Storage">
          <ng-template matTabContent>
            <app-device-filestorage [device]="device"></app-device-filestorage>
          </ng-template>
        </mat-tab>
        <mat-tab label="Health">
          <ng-template matTabContent>
            <app-device-health [device]="device"></app-device-health>
          </ng-template>
        </mat-tab>
        <mat-tab label="Downloadable Content">
          <ng-template matTabContent>
            <app-device-downloadable-content [device]="device"></app-device-downloadable-content>
          </ng-template>
        </mat-tab>
        <mat-tab label="Failed Jobs">
          <ng-template matTabContent>
            <app-device-failed-job [device]="device"></app-device-failed-job>
          </ng-template>
        </mat-tab>
        <mat-tab label="File Upload Log">
          <ng-template matTabContent>
            <app-device-file-upload-log [device]="device"></app-device-file-upload-log>
          </ng-template>
        </mat-tab>
        <mat-tab label="Device Activity Log">
          <ng-template matTabContent>
            <app-device-activity-log [device]="device"></app-device-activity-log>
          </ng-template>
        </mat-tab>
        <mat-tab label="User Activity Log">
          <ng-template matTabContent>
            <app-device-user-activity-log [device]="device"></app-device-user-activity-log>
          </ng-template>
        </mat-tab>
        <mat-tab label="Remote Desktop">
          <ng-template matTabContent>
            <app-device-remote-desktop [device]="device" (deviceChange)="loadDevice()"></app-device-remote-desktop>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  } @else {
    <div style="display: block; margin: 20vh auto 0 auto; height: 62vh; width: 100%">
      <mat-progress-spinner diameter="150" mode="indeterminate" style="margin: 0 auto"></mat-progress-spinner>
    </div>
  }
</div>

