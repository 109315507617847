import { Router } from '@angular/router';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';

@Component({
    selector: 'sub-header',
    templateUrl: './sub-header.component.html',
    styleUrls: ['./sub-header.component.scss'],
    standalone: false
})
export class SubHeaderComponent implements OnInit, OnDestroy {
  @Input() label!: string;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  goHome(): void {
    this.router.navigate(['devicelist']);
  }
}
