@for (next of getNextStatus(device); track next) {
  <app-asc-icon-button
    [ngClass]="'action-icon-button'"
    [appAscTooltip]="next.actionName"
    class="small-button"
    size="small"
    (btnClicked)="[$event.stopPropagation(), deviceAction(next.action)]"
    >{{ next.matIcon }}</app-asc-icon-button
    >
  }
