import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import { DateRange, DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { MatMenuTrigger } from '@angular/material/menu';
import moment, { Moment } from 'moment';
import { Observable, Subscription, of } from 'rxjs';
import { ISO_DATE_FORMAT } from 'src/app/utils/date-formats';
import { dateToTimeString, isoDatePattern, timePattern, timeStringToDate } from 'src/app/utils/date-time-utils';
import { DAY_RANGE, HOUR_RANGE, MINUTE_RANGE, SECOND_MULTIPLIERS, WEEK_RANGE } from '../../constants/DateRangePickerConstants';
import { AscAutocompleteProperties } from '../../interfaces/AscAutocompleteProperties';
import { DateRangePickerResponse, DateRangeRelativeResponse } from '../../interfaces/DateRangePickerResponse';
import { DateRangeRelative, DateRangeRelativeCustom } from '../../interfaces/DateRangeRelative';

@Component({
    selector: 'app-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.scss'],
    providers: [
        {
            provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
            useClass: DefaultMatCalendarRangeStrategy,
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: ISO_DATE_FORMAT },
    ],
    standalone: false
})
export class DateRangePickerComponent implements OnInit, OnDestroy {
  selectedRelativeSeconds: number | null = null;
  rangeMinutes = MINUTE_RANGE;
  rangeHours = HOUR_RANGE;
  rangeDays = DAY_RANGE;
  rangeWeeks = WEEK_RANGE;
  units$: Observable<DateRangeRelativeCustom[]> = of([
    {
      label: 'Minutes',
      unit: 'minute',
      secondMultiplicator: SECOND_MULTIPLIERS.TO_MINUTES,
    },
    {
      label: 'Hours',
      unit: 'hour',
      secondMultiplicator: SECOND_MULTIPLIERS.TO_HOURS,
    },
    {
      label: 'Days',
      unit: 'day',
      secondMultiplicator: SECOND_MULTIPLIERS.TO_DAYS,
    },
    {
      label: 'Weeks',
      unit: 'week',
      secondMultiplicator: SECOND_MULTIPLIERS.TO_WEEKS,
    },
  ]);
  customConfig: AscAutocompleteProperties<DateRangeRelativeCustom> = {
    humanizeOption: (val) => val.label,
    equals: (a, b) => a === b,
    filterPredicate: (val, input) => val.label.toLowerCase().startsWith(input.toLowerCase()),
    placeholder: 'Unit',
  };

  subs$ = new Subscription();
  @Input() tabIndex = 0;
  @Input() dateRangeIn: DateRange<Moment | null> = new DateRange(null, null);
  dateRange: DateRange<Moment | null> = new DateRange(null, null);
  @Input() timeRange: DateRangeRelativeResponse | null = null;
  @Output() absoluteRange = new EventEmitter<DateRangePickerResponse<'absolute'>>();
  @Output() relativeRange = new EventEmitter<DateRangePickerResponse<'relative'>>();
  @ViewChild('dateRangePickerTrigger') dateRangePickerTrigger: MatMenuTrigger;
  fromDateControl: FormControl<string | null> = new FormControl(null, { validators: [Validators.pattern(isoDatePattern)] });
  toDateControl: FormControl<string | null> = new FormControl(null, { validators: [Validators.pattern(isoDatePattern)] });
  fromTimeControl: FormControl<string | null> = new FormControl(null, { validators: [Validators.pattern(timePattern)] });
  toTimeControl: FormControl<string | null> = new FormControl(null, { validators: [Validators.pattern(timePattern)] });
  customRelativeInputControl: FormControl<number | null> = new FormControl(null, { validators: [Validators.pattern(/^(?!0)([1-9]\d{0,3}|9999)$/)] }); // pattern: number between 1-9999
  customRelativeSelectorControl: FormControl<DateRangeRelativeCustom | null> = new FormControl(null, { validators: [Validators.required] });
  formats: MatDateFormats;
  init = true;

  constructor(public datePipe: DatePipe, @Inject(MAT_DATE_FORMATS) dateFormats: MatDateFormats) {
    this.formats = dateFormats;
    this.controlListeners();
  }
  ngOnInit(): void {
    if (this.dateRangeIn.start) this.fromDateControl.setValue(this.formatting(this.dateRangeIn.start));
    if (this.dateRangeIn.end) this.toDateControl.setValue(this.formatting(this.dateRangeIn.end));
  }

  controlListeners() {
    this.subs$.add(
      this.fromDateControl.valueChanges.subscribe((from) => {
        if (this.fromDateControl.valid && from) {
          const startMoment = moment(from);
          this.dateRange = new DateRange(startMoment, null);
          this.fromTimeControl.setValue(dateToTimeString(startMoment.toDate()));
        } else {
          this.dateRange = new DateRange(null, null);
          this.fromTimeControl.setValue(null);
        }
      })
    );
    this.subs$.add(
      this.toDateControl.valueChanges.subscribe((to) => {
        if (this.fromDateControl.valid && to) {
          const endMoment = moment(to);
          this.dateRange = new DateRange(this.dateRange.start, endMoment);
          this.toTimeControl.setValue(dateToTimeString(endMoment.toDate()));
        } else {
          this.dateRange = new DateRange(this.dateRange.start, null);
          this.toTimeControl.setValue(null);
        }
      })
    );
    this.subs$.add(
      this.fromTimeControl.valueChanges.subscribe((from) => {
        if (this.fromTimeControl.valid && from) {
          const dateTime = moment(timeStringToDate(from));
          if (this.dateRange.start) {
            const startMoment = this.dateRange.start;
            startMoment.hours(dateTime.hour());
            startMoment.minutes(dateTime.minute());
            startMoment.seconds(dateTime.second());
            this.dateRange = new DateRange(startMoment, this.dateRange.end);
          }
        }
      })
    );
    this.subs$.add(
      this.toTimeControl.valueChanges.subscribe((to) => {
        if (this.fromTimeControl.valid && to) {
          const dateTime = moment(timeStringToDate(to));
          if (this.dateRange.end) {
            const endMoment = this.dateRange.end;
            endMoment.hours(dateTime.hour());
            endMoment.minutes(dateTime.minute());
            endMoment.seconds(dateTime.second());
            this.dateRange = new DateRange(this.dateRange.start, endMoment);
          }
        }
      })
    );
    this.subs$.add(
      this.customRelativeInputControl.valueChanges.subscribe((customInput) => {
        if (customInput && this.customRelativeInputControl.valid && this.customRelativeSelectorControl.value?.secondMultiplicator) {
          this.selectedRelativeSeconds = customInput * this.customRelativeSelectorControl.value.secondMultiplicator;
          this.timeRange = {
            unit: this.customRelativeSelectorControl.value.unit,
            seconds: this.selectedRelativeSeconds,
            label: customInput.toString(),
          };
        }
      })
    );
    this.subs$.add(
      this.customRelativeSelectorControl.valueChanges.subscribe((unit) => {
        if (
          unit &&
          this.customRelativeInputControl.valid &&
          this.customRelativeInputControl.value &&
          unit.secondMultiplicator &&
          this.customRelativeSelectorControl.value
        ) {
          this.selectedRelativeSeconds = this.customRelativeInputControl.value * unit.secondMultiplicator;
          this.timeRange = {
            unit: this.customRelativeSelectorControl.value.unit,
            seconds: this.selectedRelativeSeconds,
            label: this.customRelativeInputControl.value.toString(),
          };
        }
      })
    );
  }

  changeIndex(index: number) {
    this.tabIndex = index;
  }
  formatting = (moment: Moment | null) => {
    if (!moment) return null;
    if (!moment.format) return moment as unknown as string;
    return moment.format(this.formats ? this.formats.display.dateInput : 'YYYY-MM-DD');
  };

  dateRangeChange(event: Moment) {
    if (this.dateRange && !this.dateRange.start) {
      event.hours(0);
      event.hours(0);
      event.seconds(0);
      this.fromDateControl.setValue(this.formatting(event));
    } else if (this.dateRange && this.dateRange.start && !this.dateRange.end && this.dateRange.start <= event) {
      event.hours(23);
      event.minutes(59);
      event.seconds(59);
      this.toDateControl.setValue(this.formatting(event));
      this.toTimeControl.setValue(dateToTimeString(event.toDate()));
    } else if (this.dateRange && this.dateRange.start && this.dateRange.start > event) {
      this.fromDateControl.setValue(this.formatting(event));
      this.toDateControl.setValue(null);
    } else if (this.dateRange && this.dateRange.start && this.dateRange.end) {
      this.toDateControl.setValue(null);
      this.fromDateControl.setValue(this.formatting(event));
    }
  }

  selectRange(range: DateRangeRelative) {
    this.selectedRelativeSeconds = range.seconds;
    this.timeRange = {
      seconds: this.selectedRelativeSeconds,
      unit: range.unit,
      label: range.label,
    };
    if (this.customRelativeInputControl.value !== this.selectedRelativeSeconds) this.customRelativeInputControl.setValue(null);
  }
  checkValidity(): boolean {
    if (
      this.tabIndex === 0 &&
      this.dateRange.end &&
      this.dateRange.start &&
      this.toDateControl.valid &&
      this.toTimeControl.valid &&
      this.fromDateControl.valid &&
      this.fromTimeControl.valid
    )
      return true;
    else if (this.tabIndex === 1 && this.selectedRelativeSeconds) return true;
    return false;
  }

  returnRange() {
    if (this.tabIndex === 0 && this.dateRange.end && this.dateRange.start) {
      this.absoluteRange.emit({
        type: 'absolute',
        range: this.dateRange,
      });
    } else if (this.tabIndex === 1 && this.selectedRelativeSeconds) {
      this.relativeRange.emit({
        type: 'relative',
        range: this.timeRange,
      });
    }
    this.closeMenu();
  }

  closeMenu() {
    this.dateRangePickerTrigger.closeMenu();
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }
}
