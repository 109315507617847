<div>
  <mat-toolbar style="display: flex; justify-content: center">
    <h3>Upload File</h3>
  </mat-toolbar>
</div>

<div>
  <p style="margin: 30px; text-align: center">Choose a file to upload to the software library</p>
</div>
<form [formGroup]="formGroup">
  <div style="margin: 30px 90px; display: flex; flex-direction: column; justify-content: center">
    <div style="display: flex; flex-direction: row">
      <div style="flex: 3; display: flex; flex-direction: column; margin-right: 10px">
        <div style="display: flex; justify-content: center; margin-top: 40px">
          <div>
            @if (!editMode) {
            <input style="margin-left: 70px" type="file" (change)="onChange($event)" />
            } @if (formGroup.controls.file.dirty && formGroup.controls.file.invalid) {
            <mat-error style="display: flex; justify-content: center; font-size: small; margin-top: 5px"> Please choose a File </mat-error>
            }
          </div>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 40px">
          <mat-form-field>
            <input matInput placeholder="Display Name" name="displayName" [formControl]="formGroup.controls.displayName" />
            @if (getMatError('displayName')) {
            <mat-error> Please enter the display name </mat-error>
            }
          </mat-form-field>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 40px">
          <mat-form-field>
            <textarea [formControl]="formGroup.controls.summary" matInput placeholder="Summary" rows="3"></textarea>
            @if (getMatError('summary')) {
            <mat-error> Please enter a summary </mat-error>
            }
          </mat-form-field>
        </div>
        <div style="display: flex; justify-content: space-around; margin-top: 50px">
          <mat-form-field appearance="fill">
            <mat-label>File Type</mat-label>
            <input type="text" placeholder="File Type" matInput [formControl]="formGroup.controls.fileType" [matAutocomplete]="auto" />
            <mat-icon matSuffix>arrow_drop_down</mat-icon>
            <mat-autocomplete #auto="matAutocomplete">
              @for (y of fileTypes; track y) {
              <mat-option [value]="y.value">{{ y.viewValue }}</mat-option>
              }
            </mat-autocomplete>
            @if (getMatError('fileType')) {
            <mat-error> Please choose a File type </mat-error>
            }
          </mat-form-field>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 40px">
          <mat-form-field>
            <input matInput placeholder="Version" name="version" [formControl]="formGroup.controls.fileVersion" />
            @if (getMatError('fileVersion')) {
            <mat-error> Please enter the version </mat-error>
            }
          </mat-form-field>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 40px">
          <mat-form-field>
            <mat-label>Download Policy</mat-label>
            <input type="text" placeholder="Download Policy" matInput [formControl]="formGroup.controls.downloadPolicy" [matAutocomplete]="auto1" />
            <mat-icon matSuffix>arrow_drop_down</mat-icon>
            <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayFn">
              @for (y of downloadPolicy; track y) {
              <mat-option [value]="y">
                {{ y.viewValue }}
              </mat-option>
              }
            </mat-autocomplete>
            @if (getMatError('downloadPolicy')) {
            <mat-error> Please select the download policy </mat-error>
            }
          </mat-form-field>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 40px">
          <mat-form-field>
            <mat-label>Execution Policy</mat-label>
            <input type="text" placeholder="Execution Policy" matInput [formControl]="formGroup.controls.executionPolicy" [matAutocomplete]="auto2" />
            <mat-icon matSuffix>arrow_drop_down</mat-icon>
            <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFn">
              @for (y of executionPolicy; track y) {
              <mat-option [value]="y">
                {{ y.viewValue }}
              </mat-option>
              }
            </mat-autocomplete>
            @if (getMatError('executionPolicy')) {
            <mat-error> Please select the download policy </mat-error>
            }
          </mat-form-field>
        </div>
      </div>
      <div style="flex: 1"></div>
      <div style="flex: 3; display: flex; flex-direction: column; margin-left: 10px">
        <div style="display: flex; justify-content: center; margin-top: 40px">
          @if (!editMode) {
          <mat-form-field>
            <input matInput placeholder="Checksum" name="checksum" [formControl]="formGroup.controls.checksum" />
            @if (getMatError('checksum')) {
            <mat-error> Please enter the CRC32 checksum of the file </mat-error>
            }
          </mat-form-field>
          }
        </div>
        <div style="display: flex; justify-content: center; margin-top: 40px">
          @if (availableDeviceTypes$ | async; as _deviceTypes) {
          <mat-form-field>
            <mat-label>Device Types</mat-label>
            <mat-select name="deviceTypes" [formControl]="formGroup.controls.deviceTypes" multiple>
              @for (deviceType of _deviceTypes; track deviceType) {
              <mat-option [value]="deviceType.value">
                {{ deviceType.viewValue }}
              </mat-option>
              }
            </mat-select>
            @if (getMatError('deviceTypes')) {
            <mat-error> Please enter a device type </mat-error>
            }
          </mat-form-field>
          } @else { @if (availableDevicesLoadingError$ | async) {
          <mat-error>Something went wrong: Can not get Device Types</mat-error>
          } @else {
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          } }
        </div>
        <div style="display: flex; flex-direction: column; justify-content: center; margin-top: 40px">
          <mat-form-field>
            <textarea matInput placeholder="Applicability criteria" name="applicabilityCriteria" [formControl]="formGroup.controls.applicabilityCriteria">
            </textarea>
            @if (getMatError('applicabilityCriteria')) {
            <mat-error> Please enter the applicability criteria </mat-error>
            }
          </mat-form-field>
        </div>
      </div>
    </div>

    @if (loading) {
    <div style="display: flex; justify-content: center; margin-top: 40px">
      {{ uploadStep }}
    </div>
    }
    <div style="display: flex; justify-content: center; margin-top: 40px">
      @if (loading) {
      <mat-progress-bar [value]="uploadProgress" [mode]="editMode ? 'indeterminate' : 'determinate'"></mat-progress-bar>
      }
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 20px">
      @if (!editMode) {
      <button mat-raised-button class="c-primary-button-active" (click)="warnIfFormNotValid('upload')" [disabled]="loading">Upload</button>
      } @if (editMode) {
      <button mat-raised-button class="c-primary-button-active" (click)="warnIfFormNotValid('update')" [disabled]="loading">Update</button>
      }
      <button mat-raised-button class="c-secondary-button-active" style="margin-left: 50px; text-align: center" (click)="closeDialog()">Close</button>
    </div>
  </div>
</form>
