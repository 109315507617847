<mat-toolbar class="mat-elevation-z4" style="background: #143489; position: sticky; top: 0; z-index: 5">
  <!-- Alcon Theme color -->

  <div class="c-header-grid">
    <a (click)="goHome()">
      <div class="c-header-logo">
        <img src="./../../assets/AlconBlue.png" style="max-width: 100px" />
      </div>
    </a>
    <div class="c-header-title-wrapper">
      <p class="c-header-title-text">Service Cloud</p>
    </div>

    <div class="c-header-menu-wrapper">
      <div style="display: flex; align-items: center">
        <button mat-icon-button class="history-button" (click)="historyButtonClicked()">
          <mat-icon
            [matBadge]="historyService.unseenMessagesCount$ | async"
            [matBadgeHidden]="(historyService.unseenMessagesCount$ | async) === 0"
            matBadgeColor="warn"
            >notifications</mat-icon
          >
        </button>
      </div>
      <div>
        <button mat-flat-button [mat-menu-trigger-for]="menu" class="menu-button">
          <mat-icon>menu</mat-icon>
          Menu
        </button>

        <mat-menu #menu="matMenu">
          <button mat-menu-item [appAscTooltip]="'Manage files'" [showDelay]="1000" (click)="softwareLibraryBtnClick()">
            <mat-icon>folder</mat-icon>
            <span>Software Library</span>
          </button>
          <button mat-menu-item [appAscTooltip]="'Add, Remove & Manage Log Parsers'" [showDelay]="1000" (click)="parserMgmtBtnClick()">
            <mat-icon>document_scanner</mat-icon>
            <span>Log Parsers Management</span>
          </button>
          <button mat-menu-item [appAscTooltip]="'Manage Device Types'" [showDelay]="1000" (click)="deviceTypeMgmtBtnClick()">
            <mat-icon>construction</mat-icon>
            <span>Device Type Management</span>
          </button>
          <button mat-menu-item [appAscTooltip]="'View ongoing file uploads'" [showDelay]="1000" (click)="ongoingUploadsBtnClick()">
            <mat-icon>cloud_upload</mat-icon>
            <span>OngoingUploads</span>
          </button>
          <button mat-menu-item [appAscTooltip]="'Failed Jobs Management'" [showDelay]="1000" (click)="failedJobs()">
            <mat-icon>report</mat-icon>
            <span>Failed Jobs Management</span>
          </button>
          <button mat-menu-item [appAscTooltip]="'Browse user activities'" [showDelay]="1000" (click)="userActivitiesBtnClick()">
            <mat-icon>people</mat-icon>
            <span>User Activities</span>
          </button>
          <button mat-menu-item [appAscTooltip]="'Show info'" [showDelay]="1000" (click)="aboutBtnClick()">
            <mat-icon>info</mat-icon>
            <span>About</span>
          </button>
        </mat-menu>
      </div>
      <div>
        <div>
          <button mat-button class="logout-button" (click)="logOut()" [appAscTooltip]="'Account: {{ username }}'"><mat-icon>logout</mat-icon>Logout</button>
        </div>

        <div class="c-header-autologout-text">
          @if (idleState === 'NOT_IDLE') {
          <p style="margin-top: 0px">Auto Logout in --:--</p>
          } @if (idleState === 'IDLE') {
          <p style="margin-top: 0px">Auto Logout in {{ timeOut | async | secondsTransformer }}</p>
          }
        </div>
      </div>
    </div>
  </div>
</mat-toolbar>
