<div class="actions-bar">
  <app-asc-text-input placeholder="Search for file" prefixIcon="search" (keyupEnter)="onSearch()" [control]="searchFileControl"></app-asc-text-input>
  <div class="flexer" style="flex: 1"></div>
  <app-asc-text-input
    [placeholder]="'Case Number'"
    prefixIcon="security"
    [control]="caseNumber"
    [appAscTooltip]="'Authorize downloading files by a case number'"
  ></app-asc-text-input>
  <div class="flexer" style="flex: 2"></div>
  <app-asc-inline-button
    style="margin-right: 20px"
    [disabled]="selectedFileIds.length < 1 || (packageDownloadRunning | async) === true"
    [matMenuTriggerFor]="packagedFilesMenu"
    #packageMenuTrigger="matMenuTrigger"
    ><mat-icon inlineIcon>download</mat-icon> Download Files</app-asc-inline-button
  >
  <app-asc-inline-button
    (btnClicked)="parseFiles()"
    style="margin-right: 20px"
    [disabled]="selectedFileIds.length < 1 || (packageDownloadRunning | async) === true"
    ><mat-icon inlineIcon>cloud_upload</mat-icon> Parse Files</app-asc-inline-button
  >
  <app-asc-inline-button (btnClicked)="openUploadFileDialog()"><mat-icon inlineIcon>upload_file</mat-icon>Upload File(s)</app-asc-inline-button>
</div>

<app-asc-configurable-table
  class="file-storage-table"
  [dataSource]="dataSource"
  #filesTable
  [columns]="columns"
  [columnCells]="[fileNameTemp, fileTypeTemp, fileSizeTemp, uploadedOnTemp, actionsTemp, uploadStatusTemp]"
  [expandable]="expandableTemp"
  [colIdentifier]="colIdentifier"
  (paginatorChange)="onPagChange($event)"
  (sortChange)="onSortChange($event)"
  (expansionChange)="rowExpanded($event)"
  [pageables]="pageables"
  [sortProps]="sort"
></app-asc-configurable-table>

<ng-template #fileNameTemp let-tableElem="tableElem">
  {{ tableElem.name.substring(tableElem.name.lastIndexOf('/') + 1) }}
</ng-template>
<ng-template #fileTypeTemp let-tableElem="tableElem">
  {{ tableElem.fileType }}
</ng-template>
<ng-template #fileSizeTemp let-tableElem="tableElem">
  {{ tableElem.size | formatFileSize }}
</ng-template>

<ng-template #uploadedOnTemp let-tableElem="tableElem">
  {{ tableElem.uploadedOn | date : 'yyyy-MM-dd, HH:mm:ss' }}
</ng-template>

<ng-template #selectTemp let-tableElem="tableElem">
  <mat-checkbox (change)="toggleFileUidForDownload($event.checked, tableElem.fileId)" (click)="$event.stopPropagation()"></mat-checkbox>
</ng-template>

<ng-template #actionsTemp let-tableElem="tableElem">
  @if (!tableElem.inTransfer) {
  <app-asc-icon-button [appAscTooltip]="'Download File'" (btnClicked)="[$event.stopPropagation(), downloadFile(tableElem, 0)]">download</app-asc-icon-button>
  <app-asc-icon-button [appAscTooltip]="'Delete File'" (btnClicked)="[$event.stopPropagation(), deleteFile(tableElem)]">delete</app-asc-icon-button>
  } @if (tableElem.inTransfer) { @if (tableElem.transfer.status === 'InProgress' || tableElem.transfer.status === 'Pending') {
  <app-asc-icon-button appAscTooltip="Cancel Upload" (btnClicked)="[$event.stopPropagation(), cancelUpload(tableElem)]">cancel</app-asc-icon-button>
  } }
  <mat-checkbox
    class="select-file-checkbox"
    (change)="toggleFileUidForDownload($event.checked, tableElem.fileId)"
    (click)="$event.stopPropagation()"
    [checked]="fileIsSelected(tableElem.fileId)"
  ></mat-checkbox>
</ng-template>

<ng-template #uploadStatusTemp let-tableElem="tableElem">
  @if (tableElem.inTransfer) { @if (tableElem.transfer.status === 'InProgress') {
  <mat-icon class="status-icon loading-icon rotating" appAscTooltip="In Progress">sync</mat-icon>
  } @if (tableElem.transfer.status === 'Pending') {
  <mat-icon class="status-icon pending-icon" appAscTooltip="Pending">hourglass_top</mat-icon>
  } @if (tableElem.transfer.status === 'Cancelled') {
  <mat-icon class="status-icon cancelled-icon" appAscTooltip="Cancelled">cancel_presentation</mat-icon>
  } @if (tableElem.transfer.status === 'Failure') {
  <mat-icon class="status-icon failure-icon" appAscTooltip="Upload Failed">report</mat-icon>
  } @if (tableElem.transfer.status === 'Cancelled' || tableElem.transfer.status === 'Failure') {
  <mat-icon class="icon-rmv-transfer" appAscTooltip="Remove" (click)="removeTransfer(tableElem)">close</mat-icon>
  } }
</ng-template>

<ng-template #expandableTemp let-tableElem="tableElem" let-rowExpanded="rowExpanded">
  @if (tableElem.inTransfer) { @if (tableElem.transfer.status === 'InProgress') {
  <mat-progress-bar
    mode="determinate"
    style="width: 80%; margin: 30px auto"
    [value]="tableElem.transfer.progress"
    [appAscTooltip]="tableElem.transfer.progress + ' %'"
  >
  </mat-progress-bar>
  } } @if (!tableElem.inTransfer) {
  <p>
    <b>Path: </b> <span style="margin-left: 16px" [appClipboardCopy]="tableElem.name">{{ tableElem.name }}</span>
  </p>
  } @if (rowExpanded && !tableElem.inTransfer) {
  <h3 class="meta-data-header">
    Metadata
    <span><app-asc-icon-button size="small" appAscTooltip="Refresh metadata" (btnClicked)="refreshMetadata(tableElem)">refresh</app-asc-icon-button></span>
  </h3>
  <app-asc-configurable-table
    [columns]="columnsMetaData"
    style="width: 75%; min-width: 500px; display: block; margin-bottom: 30px"
    [pageables]="null"
    class="asc-table-small"
    [size]="'small'"
    [columnCells]="[metDataKey, metDataVal]"
    [dataSource]="getDataSource(tableElem)"
  ></app-asc-configurable-table>
  }
</ng-template>

<ng-template #metDataKey let-tableElem="tableElem">
  {{ tableElem.key }}
</ng-template>
<ng-template #metDataVal let-tableElem="tableElem">
  {{ tableElem.value }}
</ng-template>

<mat-menu #packagedFilesMenu="matMenu">
  <div class="packaged-download-menu" (click)="[$event.stopPropagation()]">
    <mat-checkbox [formControl]="filePackageWithMetadata">With metadata</mat-checkbox>
    <app-asc-text-input [placeholder]="'File package name'" [control]="filePackageName" [appAscTooltip]="'Specify a package file name'"></app-asc-text-input>
    <app-asc-inline-button (btnClicked)="startDownloadPackaged(filePackageName.value, filePackageWithMetadata.value, caseNumber.value)"
      >Start download</app-asc-inline-button
    >
  </div>
</mat-menu>
