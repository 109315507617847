import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import packageJson from '../../../../../package.json';
import { SharedApiService } from '../../../shared/services/shared-api.service';

@Component({
    selector: 'app-about-page',
    templateUrl: './about-page.component.html',
    styleUrls: ['./about-page.component.scss'],
    standalone: false
})
export class AboutPageComponent implements OnInit {
  isLoading = false;
  isError = false;
  ascVersion: string;
  parsingControllerVersion: string;
  frontendVersion: string = packageJson.version;

  constructor(private apiService: SharedApiService, private titleService: Title) {}

  ngOnInit(): void {
    //  init the title
    this.titleService.setTitle('ASC - About');
    this.isLoading = true;
    this.apiService.getVersion().subscribe({
      next: (version) => {
        this.ascVersion = version.version;
        this.isLoading = false;
        this.parsingControllerVersion = version.parsingControllerVersion;
      },
      error: (error) => {
        console.error('Error in version api', error);
      },
    });
  }
}
