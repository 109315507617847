import { SortDirection } from '@angular/material/sort';
import { catchError, finalize, of } from 'rxjs';
import { DataSourceBaseImpl } from 'src/app/shared/interfaces/DataSourceBase';
import { OngoingUploadsService } from '../services/ongoing-uploads.service';
import { OngoingUploadsContent } from './OngoingUploadsContent';

export class OngoingUploadsDataSource extends DataSourceBaseImpl<OngoingUploadsContent> {
  constructor(private apiService: OngoingUploadsService) {
    super();
  }

  getData(pageSize: number, pageIndex: number, sortColumn: string, sortDirection: SortDirection) {
    this.loadingSubject.next(true);
    this.errorSubject.next(null);
    this.totalSizeSubject.next(0);
    this.dataSubject.next([]);
    this.apiService
      .getOngoingUploads(pageSize, pageIndex, sortColumn, sortDirection)
      .pipe(
        catchError((err) => {
          this.errorSubject.next(err);
          return of();
        }),
        finalize(() => {
          this.loadingSubject.next(false);
        })
      )
      .subscribe((ongoingUploads) => {
        if (ongoingUploads.content) {
          this.dataSubject.next(ongoingUploads.content);
        }
        this.totalSizeSubject.next(ongoingUploads.totalSize);
      });
  }
}
