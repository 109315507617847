import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort, SortDirection } from '@angular/material/sort';
import { Router } from '@angular/router';
import { OngoingUploadsContent } from 'src/app/ongoing-uploads/interfaces/OngoingUploadsContent';
import { AppStorageService } from 'src/app/shared/app-storage.service';
import { ConfigPageEvent } from 'src/app/shared/components/asc-configurable-table/asc-configurable-table.component';
import { ConfigureableTableColumn } from 'src/app/shared/interfaces/ConfigureableTableColumn';
import { OngoingUploadsDataSource } from '../../interfaces/OngoingUploadDataSource';
import { OngoingUploadsService } from '../../services/ongoing-uploads.service';

@Component({
  selector: 'app-ongoing-uploads',
  templateUrl: './ongoing-uploads.component.html',
  styleUrls: ['./ongoing-uploads.component.scss'],
  standalone: false,
})
export class OngoingUploadsComponent implements OnInit, OnDestroy {
  columns: ConfigureableTableColumn<OngoingUploadsContent>[] = [
    { name: 'deviceId', header: 'Device ID', sortable: true },
    { name: 'customer', header: 'Customer', sortable: true },
    { name: 'fileName', header: 'File Name', sortable: true },
    { name: 'uploadStart', header: 'Start Time', sortable: true },
    { name: 'totalFileSize', header: 'File Size', sortable: true },
    { name: 'currentUploadSize', header: 'Current Upload Size', sortable: true },
    { name: 'progress', header: 'Progress', sortable: true },
  ];

  dataSource: OngoingUploadsDataSource;
  sort: Sort;
  pageables: ConfigPageEvent;

  interval: NodeJS.Timeout;

  constructor(private router: Router, private store: AppStorageService, private apiService: OngoingUploadsService) {
    this.getStorageData();
    this.dataSource = new OngoingUploadsDataSource(this.apiService);
  }

  ngOnInit(): void {
    this.interval = setInterval(() => {
      this.loadData();
    }, 3000);
    this.loadData();
    window.onbeforeunload = () => this.saveStorageData();
  }

  getStorageData() {
    this.sort = {
      active: this.store.getValue<string>('ongoinguploads_sort_column') || '',
      direction: this.store.getValue<SortDirection>('ongoinguploads_sort_direction') || '',
    };
    this.pageables = {
      pageSize: this.store.getValue<number>('ongoinguploads_pagination_pagesize') || 10,
      pageIndex: 0,
      pageSizeOptions: [10, 20, 50],
    };
  }

  sortChange(sort: Sort) {
    this.sort = sort;
    this.loadData();
  }

  paginatorListener(pagChange: PageEvent) {
    this.pageables = {
      ...this.pageables,
      pageIndex: pagChange.pageIndex,
      pageSize: pagChange.pageSize,
    };
    this.loadData();
  }
  saveStorageData() {
    this.store.setValue('ongoinguploads_sort_column', this.sort.active ? this.sort.active : null);
    this.store.setValue('ongoinguploads_sort_direction', this.sort.direction);
    this.store.setValue('ongoinguploads_pagination_pagesize', this.pageables.pageSize);
  }

  loadData() {
    this.pageables = {
      ...this.pageables,
      pageIndex: 0,
    };
    this.dataSource.getData(this.pageables.pageSize, this.pageables.pageIndex, this.sort.active, this.sort.direction);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  calculatePercentage(totalSize: number, currentSize: number): number {
    return Math.ceil((currentSize / totalSize) * 100);
  }
}
