import { CdkTreeModule } from '@angular/cdk/tree';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgIdleModule } from '@ng-idle/core';
import { ToastrModule } from 'ngx-toastr';
import { AdditionalPagesModule } from './additional-pages/additional-pages.module';
import { AppRoutingModule, routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { AppInterceptor } from './authentication/services/auth.httpinterceptor.service';
import { DeviceFileModule } from './device-file/device-file.module';
import { DeviceModule } from './device/device.module';
import { ErrorHandlingModule } from './error-handling/error-handling.module';
import { ErrorHandlerService } from './error-handling/services/error-handler.service';
import { FailedJobManagementModule } from './failed-job-management/failed-job-management.module';
import { LoggingModule } from './logging/logging.module';
import { MarginalsModule } from './marginals/marginals.module';
import { MaterialModule } from './material/material.module';
import { OngoingUploadsModule } from './ongoing-uploads/ongoing-uploads.module';
import { SoftwareManagementModule } from './software-management/software-management.module';

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [CdkTreeModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot(routes),
        ToastrModule.forRoot({ preventDuplicates: true }),
        MatSidenavModule,
        // Used for auto logout
        NgIdleModule.forRoot(),
        // Material components
        MaterialModule,
        // App modules
        AuthenticationModule,
        MarginalsModule,
        DeviceModule,
        DeviceFileModule,
        SoftwareManagementModule,
        LoggingModule,
        OngoingUploadsModule,
        AdditionalPagesModule,
        FailedJobManagementModule,
        ErrorHandlingModule], providers: [
        //AuthService,
        //AuthGuard,
        { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
        Title,
        {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: { color: 'primary' },
        },
        //FileTransferService,
        ErrorHandlerService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
