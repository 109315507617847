import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort, SortDirection } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppStorageService } from 'src/app/shared/app-storage.service';
import { ConfigPageEvent } from 'src/app/shared/components/asc-configurable-table/asc-configurable-table.component';
import { ConfigureableTableColumn } from 'src/app/shared/interfaces/ConfigureableTableColumn';
import { DownloadableContentCountPerDevice } from 'src/app/software-management/interfaces/DownloadableContentPerDevice';
import { SoftwarePerDeviceDataSource } from '../../interfaces/SoftwarePerDeviceDataSource';
import { SoftwareManagementService } from '../../services/software-management.service';

@Component({
  selector: 'app-software-per-devices',
  templateUrl: './software-per-devices.component.html',
  styleUrls: ['./software-per-devices.component.scss'],
  standalone: false,
})
export class SoftwarePerDevicesComponent implements OnInit {
  tableColumns: ConfigureableTableColumn<DownloadableContentCountPerDevice>[] = [
    {
      name: 'deviceId',
      header: 'Device ID',
      sortable: true,
    },
    {
      name: 'deviceType',
      header: 'Device Type',
      sortable: true,
    },
    {
      name: 'serialNumber',
      header: 'Serial Number',
      sortable: true,
    },
    {
      name: 'assigned',
      header: 'Assigned Software',
      sortable: false,
    },
    {
      name: 'unassigned',
      header: 'Unassigned Software',
      sortable: false,
    },
    {
      name: 'actions',
      header: 'Actions',
      sortable: false,
    },
  ];
  subs$: Subscription = new Subscription();

  dataSource: SoftwarePerDeviceDataSource;

  deviceIdControl = new FormControl<string>('');
  sort: Sort;
  pageables: ConfigPageEvent;

  constructor(private router: Router, private apiService: SoftwareManagementService, private store: AppStorageService) {
    this.getStorageData();
    this.dataSource = new SoftwarePerDeviceDataSource(this.apiService);
  }

  ngOnInit(): void {
    this.loadData();
    window.onbeforeunload = () => this.saveStorageData();
  }

  getStorageData() {
    this.sort = {
      active: this.store.getValue<string>('softwareperdevice_sort_column') || '',
      direction: this.store.getValue<SortDirection>('softwareperdevice_sort_direction') || '',
    };
    this.pageables = {
      pageSize: this.store.getValue<number>('softwareperdevice_pagination_pagesize') || 10,
      pageIndex: 0,
      pageSizeOptions: [10, 20, 50],
    };
    this.deviceIdControl.setValue(this.store.getValue<string>('softwareperdevice_filter_searchtext') || '');
  }

  sortChange(sort: Sort) {
    this.sort = sort;
    this.loadData();
  }

  paginatorListener(pagChange: PageEvent) {
    this.pageables = {
      ...this.pageables,
      pageIndex: pagChange.pageIndex,
      pageSize: pagChange.pageSize,
    };
    this.loadData();
  }
  saveStorageData() {
    this.store.setValue('softwareperdevice_sort_column', this.sort.active ? this.sort.active : null);
    this.store.setValue('softwareperdevice_sort_direction', this.sort.direction);
    this.store.setValue('softwareperdevice_pagination_pagesize', this.pageables.pageSize);
    this.store.setValue('softwareperdevice_filter_searchtext', this.deviceIdControl.value || '', 'string', true);
  }

  loadData() {
    this.pageables = {
      ...this.pageables,
      pageIndex: 0,
    };
    this.dataSource.getData(this.deviceIdControl.value || '', this.pageables.pageSize, this.pageables.pageIndex, this.sort.active, this.sort.direction);
  }

  showDetails(element: DownloadableContentCountPerDevice) {
    console.log('details', element);

    this.router.navigate(['devicedetails', element.deviceId], {
      queryParams: { t: 2 },
    });
  }
}
