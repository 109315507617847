<div>
  <mat-toolbar style="display: flex; justify-content: center">
    <h3>Upload Files</h3>
  </mat-toolbar>
</div>

<div>
  <p style="margin: 30px; text-align: center">Choose files to upload to the device {{ data.deviceId }}'s cloud storage</p>
</div>
<form [formGroup]="formGroup">
  <div style="margin: 30px 90px; display: flex; flex-direction: column; justify-content: center">
    <div style="display: flex; justify-content: center">
      <input style="margin-left: 70px" type="file" (change)="onChange($event)" multiple />
    </div>
    <div>
      @if (formGroup.controls.files.invalid && formGroup.controls.files.dirty) {
      <mat-error style="display: flex; justify-content: center; font-size: small; margin-top: 5px">Please choose a File </mat-error>
      }
    </div>

    <div style="display: flex; justify-content: center; margin-top: 20px">
      <button mat-raised-button class="c-primary-button-active" style="margin: auto" (click)="onUpload()" [disabled]="formGroup.invalid">Upload</button>
      <button mat-raised-button class="c-secondary-button-active" style="margin: auto; text-align: center" (click)="closeDialog()">Close</button>
    </div>
  </div>
</form>
