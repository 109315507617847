import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CButton } from '../../interfaces/CButton';

@Component({
    selector: 'app-asc-inline-button',
    templateUrl: './asc-inline-button.component.html',
    styleUrls: ['./asc-inline-button.component.scss'],
    standalone: false
})
export class AscInlineButtonComponent implements CButton {
  @Input() disabled = false;
  @Input() cType: 'primary' | 'secondary' = 'primary';

  @Output() btnClicked = new EventEmitter();

  cButtonClicked(evt: any) {
    this.btnClicked.emit(evt);
  }
}
