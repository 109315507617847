import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription, debounceTime, last, map, of } from 'rxjs';
import { AscCatSelectProperties } from '../../interfaces/AscCatSelectProperties';

@Component({
    selector: 'app-asc-cat-select',
    templateUrl: './asc-cat-select.component.html',
    styleUrls: ['./asc-cat-select.component.scss'],
    standalone: false
})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class AscCatSelectComponent<T = any> implements OnInit, OnDestroy {
  filteredSelected$: Observable<T[]>;
  filteredUnselected$: Observable<T[]>;

  dataAll$: Observable<{ data: T; selected: boolean }[]>;

  checkBoxData$: Observable<{ data: T; selected: boolean }[]>;

  controlSub: Subscription;

  searchControl: FormControl = new FormControl();

  @Input() props: AscCatSelectProperties<T>;
  @Input() dataAsync: Observable<T[]>;

  ngOnInit(): void {
    if (!this.props.equals) this.props.equals = this.defaultEquals;
    if (!this.props.optionLabelPredicate) this.props.optionLabelPredicate = this.defaultLabelPredicate;

    this.props.type === 'category' ? this.setSelectedAndUnselected(this.searchControl.value) : this.setCheckboxData();
    if (this.props.withSearch) {
      this.controlSub = this.searchControl.valueChanges.pipe(debounceTime(250)).subscribe((val) => {
        this.props.type === 'category' ? this.setSelectedAndUnselected(val) : this.setCheckboxData();
      });
    }
  }

  defaultEquals = (a: T, b: T) => {
    return a === b;
  };

  defaultLabelPredicate = (elem: T) => {
    if (typeof elem === 'string') {
      return elem;
    }
    return JSON.stringify(elem);
  };

  setSelectedAndUnselected(searchVal?: string) {
    if (!this.dataAsync) this.dataAsync = of([]);
    this.filteredUnselected$ = this.dataAsync.pipe(
      last(),
      map((data) => {
        if (this.props.selectedControl.value.length < 1 && !searchVal) {
          return data;
        }
        return data.filter((d) => {
          const isElem = !this.props.selectedControl.value.some((sel) =>
            this.props.equals ? this.props.equals(sel, d) : this.defaultEquals(sel, d)
          );
          if (!searchVal) return isElem;
          return isElem && this.props.filterPredicate(searchVal, d);
        });
      })
    );
    this.filteredSelected$ = this.dataAsync.pipe(
      last(),
      map((data) => {
        if (this.props.selectedControl.value.length < 1 && !searchVal) {
          return [];
        }
        return data.filter((d) => {
          const isElem = this.props.selectedControl.value.some((sel) => (this.props.equals ? this.props.equals(sel, d) : this.defaultEquals(sel, d)));
          if (!searchVal) return isElem;
          return isElem && this.props.filterPredicate(searchVal, d);
        });
      }),
      map((data) => {
        if (data.length === 0 && this.props.selectedControl.value && this.props.addIncomplete) {
          return this.props.selectedControl.value;
        }
        return data;
      })
    );
  }

  setCheckboxData() {
    this.checkBoxData$ = this.dataAsync.pipe(
      last(),
      map((data) => {
        return data.map((dat) => ({
          data: dat,
          selected: this.props.selectedControl.value.some((val) => (this.props.equals ? this.props.equals(val, dat) : this.defaultEquals(val, dat))),
        }));
      })
    );
  }

  addOption(elem: T) {
    this.props.selectedControl.setValue([elem, ...this.props.selectedControl.value]);
    this.setSelectedAndUnselected(this.searchControl.value);
  }

  removeOption(elem: T) {
    this.props.selectedControl.setValue(
      this.props.selectedControl.value.filter((val) => !(this.props.equals ? this.props.equals(val, elem) : this.defaultEquals(val, elem)))
    );
    this.setSelectedAndUnselected(this.searchControl.value);
  }

  checkBoxClicked(elem: { data: T; selected: boolean }) {
    elem.selected
      ? this.props.selectedControl.setValue(
          this.props.selectedControl.value.filter(
            (val) => !(this.props.equals ? this.props.equals(val, elem.data) : this.defaultEquals(val, elem.data))
          )
        )
      : this.props.selectedControl.setValue([elem.data, ...this.props.selectedControl.value]);
    this.setCheckboxData();
  }

  addIncomplete() {
    const inputVal = this.searchControl.value;

    if (!inputVal) return;
    if (this.props.selectedControl.value.some((val) => this.props.filterPredicate(inputVal, val))) return;
    this.props.selectedControl.setValue([inputVal, ...this.props.selectedControl.value]);
    this.setSelectedAndUnselected(this.searchControl.value);
    this.searchControl.setValue(null);
  }

  ngOnDestroy() {
    if (this.controlSub) this.controlSub.unsubscribe();
  }
}
