import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Observable, Subscription, concatMap, map, of, startWith, withLatestFrom } from 'rxjs';
import { AscAutocompleteProperties } from '../../interfaces/AscAutocompleteProperties';

interface OptionData<T> {
  data: T;
  selected: boolean;
}

@Component({
    selector: 'app-asc-autocomplete',
    templateUrl: './asc-autocomplete.component.html',
    styleUrls: ['./asc-autocomplete.component.scss'],
    standalone: false
})
export class AscAutocompleteComponent<T> implements OnInit, OnDestroy {
  @Input() properties: AscAutocompleteProperties<T>;
  @Input() data: Observable<T[]> = of([]);
  @Input() formControlName: string;
  @Input() control: FormControl<T[] | T | null>;
  @Input() clear = true;

  @Output() selectionChange = new EventEmitter<T | null | T[]>();

  filteredData$: Observable<OptionData<T>[]>;

  selectedData: T[] = [];
  selectedDataSingle: T;

  filterControl = new FormControl('');

  formGroup: FormGroup;

  setNow = false;
  subs$ = new Subscription();

  latestFromInput: T | null = null;

  constructor(private rootFormGroup: FormGroupDirective) {}
  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }

  ngOnInit(): void {
    this.formGroup = this.rootFormGroup.control;
    if (!this.properties.humanizeOption) this.properties.humanizeOption = (val: T) => String(val);
    if (this.properties.errorMessage) {
      this.filterControl = new FormControl('', [Validators.required]);
    }

    this.setFilterControlValue(this.control.value);
    this.subs$.add(
      this.control.valueChanges.subscribe((newVal) => {
        this.setFilterControlValue(newVal);
      })
    );
    this.initFilteredData();
    this.compareOnInput();
  }
  compareOnInput() {
    if (!this.properties.comparator) return;
    this.filterControl.valueChanges
      .pipe(
        map((input) => {
          return input;
        }),
        withLatestFrom(this.data, (input, values) => ({ input, values })),
        map(({ input, values }) => {
          return values.filter((val) => (input && this.properties.comparator ? this.properties.comparator(input, val) : false));
        })
      )
      .subscribe((filtered) => {
        if (filtered.length === 1) {
          this.latestFromInput = filtered[0];
        } else if (this.latestFromInput) {
          this.latestFromInput = null;
        }
      });
  }
  setLatestFromInput() {
    this.control.setValue(this.latestFromInput);
  }
  setFilterControlValue(value: T[] | T | null) {
    if (!value) {
      this.filterControl.setValue(null);
      return;
    }
    this.filterControl.setValue(
      Array.isArray(value) ? value.map((val) => this.properties.humanizeOption(val)).join(', ') : this.properties.humanizeOption(value)
    );
  }

  initFilteredData() {
    this.filteredData$ = this.filterControl.valueChanges.pipe(
      startWith(''),
      concatMap((searchVal) => {
        let splitted = '';
        if (this.setNow && !this.properties.multiple) {
          this.setNow = false;
        } else if (typeof searchVal === 'string') {
          splitted = searchVal
            ? searchVal.includes(',')
              ? searchVal.split(', ')[searchVal.split(', ').length - 1]
              : searchVal
              ? searchVal
              : ''
            : '';
        }

        return this.data.pipe(
          map((data) => {
            if (!data) return [];
            if (!splitted) {
              return data.map((elem) => ({ selected: this.dataIsSelected(elem), data: elem }));
            }
            return data.reduce((prev: { selected: boolean; data: T }[], curr) => {
              if (this.properties.filterPredicate(curr, splitted)) {
                prev.push({ selected: this.dataIsSelected(curr), data: curr });
                return prev;
              }
              return prev;
            }, []);
          })
        );
      })
    );
  }

  dataIsSelected = (val: T) => {
    return this.selectedData.some((elem) => this.properties.equals(elem, val));
  };

  getFormControl<Multi extends 'single' | 'multiple'>(): FormControl<Multi extends 'single' ? T | null : T[] | null> {
    if (this.formGroup?.controls[this.formControlName]) {
      return this.formGroup?.controls[this.formControlName] as FormControl<(Multi extends 'single' ? T : T[]) | null>;
    }
    return this.control as FormControl<(Multi extends 'single' ? T : T[]) | null>;
  }

  selected(evt: Event, option: { selected: boolean; data: T }) {
    if (this.properties.multiple) {
      evt.stopPropagation();
      evt.preventDefault();
    }

    this.toggleSelection(option);
  }

  display() {
    return this.filterControl.value ? this.filterControl.value : '';
  }

  selectionCleared() {
    this.selectedData = [];
    this.getFormControl().setValue(null);
    this.selectionChange.emit(null);
  }

  toggleSelection(option: OptionData<T>) {
    option.selected = !option.selected;

    if (!this.properties.multiple) {
      this.control.setValue(option.data);

      this.selectionChange.emit(option.data);
    } else if (option.selected && this.properties.multiple) {
      this.selectedData.push(option.data);
      this.control.setValue(this.selectedData);
      this.selectionChange.emit(this.selectedData);
    } else {
      this.selectedData.splice(
        this.selectedData.findIndex((sel) => this.properties.equals(sel, option.data)),
        1
      );
      this.control.setValue(this.selectedData);
      this.selectionChange.emit(this.selectedData);
    }

    const display = this.properties.multiple
      ? [...this.selectedData.map((sel) => this.properties.humanizeOption(sel)), ''].join(', ')
      : this.properties.humanizeOption(option.data);

    this.setNow = true;
    this.filterControl.setValue(display);
  }
}
