<div class="c-login-background" fxLayout="row" fxLayoutAlign="center center">
  <mat-card appearance="outlined" class="box" width="500px">
    <img class="c-login-company-logo" src="../../assets/alconLogo.png" style="padding: 25px" />

    @if (useOktaAutomaticLogin) {
      <p style="margin: 60px 0; text-align: center">Login with Okta in progress.<br />Please wait.</p>
    }

    @if (showError || showSessionExpiryError) {
      <mat-card appearance="outlined" class="c-login-error-notice">
        @if (showError) {
          <p class="c-login-error-notice-text">Username and password did not match</p>
        }
        @if (showSessionExpiryError) {
          <p class="c-login-error-notice-text">Session Expired. Please log in again</p>
        }
      </mat-card>
    }

    <div style="margin-top: 20px; font-size: small">Server Version: {{ ascVersion }}</div>
  </mat-card>
</div>
