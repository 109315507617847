import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, finalize, of } from 'rxjs';
import { DeviceType } from 'src/app/device/interfaces/DeviceType';
import { ToastsService } from 'src/app/error-handling/services/toasts.service';
import { DialogResponse } from 'src/app/shared/interfaces/DialogResponse';
import { ErrorHandlerService } from '../../../error-handling/services/error-handler.service';
import { DeviceApiService } from '../../services/device-api.service';

export interface DialogData {
  isUpdateMode: boolean;
  deviceType: DeviceType;
}

@Component({
    selector: 'app-device-type-dialog',
    templateUrl: './device-type-dialog.component.html',
    styleUrls: ['./device-type-dialog.component.scss'],
    standalone: false
})
export class DeviceTypeDialogComponent implements OnInit {
  formGroup: FormGroup<DeviceTypeFormGroup> = new FormGroup<DeviceTypeFormGroup>({
    name: new FormControl('', { nonNullable: true, validators: Validators.required }),
    modelType: new FormControl('', { nonNullable: true, validators: Validators.required }),
    gateKeeper: new FormControl<boolean>(false, { nonNullable: true, validators: [] }),
    quickConnection: new FormControl<boolean>(false, { nonNullable: true, validators: [] }),
    sMaxMatch: new FormControl<boolean>(false, { nonNullable: true, validators: [] }),
    updateDhs: new FormControl<boolean>(false, { nonNullable: true, validators: [] }),
    unpackArchives: new FormControl<boolean>(false, { nonNullable: true, validators: [] }),
    specialProcessingPattern: new FormControl('', { nonNullable: false }),
    uploadApprovalRule: new FormControl('', { nonNullable: false }),
  });

  data: DialogData;
  isUpdateMode = false;
  loading = false;

  constructor(
    private deviceApi: DeviceApiService,
    @Inject(MAT_DIALOG_DATA) dialogData: DialogData,
    private toasts: ToastsService,
    private dialogRef: MatDialogRef<DeviceTypeDialogComponent, Partial<DialogResponse<unknown>>>,
    private errorHandlerService: ErrorHandlerService
  ) {
    this.data = dialogData;
    if (dialogData != null) {
      this.isUpdateMode = dialogData.isUpdateMode;
    }
  }

  ngOnInit(): void {
    if (this.isUpdateMode) {
      this.formGroup.controls.name.setValue(this.data.deviceType.deviceType);
      this.formGroup.controls.modelType.setValue(this.data.deviceType.modelType);
      this.formGroup.controls.gateKeeper.setValue(this.data.deviceType.gateKeeperConnection);
      this.formGroup.controls.quickConnection.setValue(this.data.deviceType.quickConnection);
      this.formGroup.controls.sMaxMatch.setValue(this.data.deviceType.smaxMatch);
      this.formGroup.controls.updateDhs.setValue(this.data.deviceType.updateDhs);
      this.formGroup.controls.unpackArchives.setValue(this.data.deviceType.unpackUploadedArchives);
      this.formGroup.controls.specialProcessingPattern.setValue(this.data.deviceType.specialProcessingPattern);
      this.formGroup.controls.uploadApprovalRule.setValue(this.data.deviceType.uploadApprovalRule);
      this.formGroup.markAsDirty();
    }
  }

  actionButtonClicked() {
    if (this.isUpdateMode) {
      this.updateDeviceType();
    } else {
      this.addDeviceType();
    }
  }

  private addDeviceType() {
    if (this.formGroup.invalid) return;
    this.deviceApi
      .addDeviceType(
        this.formGroup.controls.name.value,
        this.formGroup.controls.modelType.value,
        '',
        false,
        this.formGroup.controls.updateDhs.value,
        this.formGroup.controls.unpackArchives.value,
        this.formGroup.controls.sMaxMatch.value,
        this.formGroup.controls.gateKeeper.value,
        this.formGroup.controls.quickConnection.value,
        this.formGroup.controls.specialProcessingPattern.value,
        this.formGroup.controls.uploadApprovalRule.value
      )
      .pipe(
        catchError((err) => {
          const errorrMessage = this.errorHandlerService.httpErrorToString(
            err,
            `Could not add new Device Type with name  ${this.formGroup.controls.name.value}`
          );
          this.toasts.raise({ title: 'Add Device Type', message: errorrMessage }, 'ERROR');
          this.closeDialog('ERROR');
          return of();
        })
      )
      .subscribe(() => {
        this.toasts.raise({ title: 'Add Device Type', message: 'Added new Device Type with name ' + this.formGroup.controls.name.value }, 'SUCCESS');
        this.closeDialog('SUCCESS');
      });
  }

  private updateDeviceType() {
    this.deviceApi
      .updateDeviceType(this.data.deviceType.deviceTypeId, {
        modelType: this.formGroup.controls.modelType.value,
        sMaxMatch: this.formGroup.controls.sMaxMatch.value,
        gateKeeperConnection: this.formGroup.controls.gateKeeper.value,
        quickConnection: this.formGroup.controls.quickConnection.value,
        updateDhs: this.formGroup.controls.updateDhs.value,
        unpackUploadedArchives: this.formGroup.controls.unpackArchives.value,
        specialProcessingPattern: this.formGroup.controls.specialProcessingPattern.value,
        uploadApprovalRule: this.formGroup.controls.uploadApprovalRule.value,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          const errorrMessage = this.errorHandlerService.httpErrorToString(err, 'Could not update device type');
          this.toasts.raise({ title: 'Edit Device', message: errorrMessage }, 'ERROR');

          this.dialogRef.close({ status: 'ERROR', code: err.status });
          return of();
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(() => {
        this.toasts.raise({ title: 'Edit Device', message: `Updated device type ${this.data.deviceType.deviceType}` }, 'SUCCESS', false);
        this.dialogRef.close({ status: 'SUCCESS', code: 201 });
      });
  }

  closeDialog(action: 'CANCEL' | 'ERROR' | 'SUCCESS') {
    this.dialogRef.close({ status: action });
  }

  closeButtonClicked() {
    this.dialogRef.close();
  }
}

interface DeviceTypeFormGroup {
  name: FormControl<string>;
  modelType: FormControl<string>;
  gateKeeper: FormControl<boolean>;
  quickConnection: FormControl<boolean>;
  sMaxMatch: FormControl<boolean>;
  updateDhs: FormControl<boolean>;
  unpackArchives: FormControl<boolean>;
  specialProcessingPattern: FormControl<string | null>;
  uploadApprovalRule: FormControl<string | null>;
}
