<span [matMenuTriggerFor]="dateRangePicker" #dateRangePickerTrigger="matMenuTrigger">
  <ng-content select="[datePickerTrigger]"></ng-content>
</span>

<mat-menu #dateRangePicker="matMenu" (click)="$event.stopPropagation()" class="date-range-picker-menu">
  <div class="date-range-picker-container" (click)="$event.stopPropagation()">
    <div class="date-range-picker-tab-bar">
      <app-asc-button type="flat" (btnClicked)="changeIndex(0)" [active]="tabIndex === 0">Absolute</app-asc-button>
      <span class="horizontal-spacer"></span>
      <app-asc-button type="flat" (btnClicked)="changeIndex(1)" [active]="tabIndex === 1">Relative</app-asc-button>
    </div>
  </div>

  @switch(tabIndex){ @case(0){
  <ng-container [ngTemplateOutlet]="absolutePicker"></ng-container>
  } @case(1){
  <ng-container [ngTemplateOutlet]="relativePicker"></ng-container>
  } }

  <div class="action-buttons-container">
    <app-asc-button type="cancel" (btnClicked)="closeMenu()">Cancel</app-asc-button>
    <app-asc-button type="primary" [disabled]="!checkValidity()" (btnClicked)="returnRange()">Apply</app-asc-button>
  </div>
</mat-menu>

<ng-template #absolutePicker>
  <div class="absolute-picker-container" (click)="$event.stopPropagation()">
    <mat-calendar [selected]="dateRange" (selectedChange)="dateRangeChange($event)"></mat-calendar>
    <div class="picker-inputs">
      <p>from:</p>
      <app-asc-text-input [control]="fromDateControl" placeholder="YYYY-MM-DD"></app-asc-text-input>
      <app-asc-text-input [control]="fromTimeControl" placeholder="hh:mm:ss"></app-asc-text-input>
    </div>
    <div class="picker-inputs">
      <p>to:</p>
      <app-asc-text-input [control]="toDateControl" placeholder="YYYY-MM-DD"></app-asc-text-input>
      <app-asc-text-input [control]="toTimeControl" placeholder="hh:mm:ss"></app-asc-text-input>
    </div>
  </div>
</ng-template>
<ng-template #relativePicker>
  <div class="relative-picker-container" (click)="$event.stopImmediatePropagation()">
    <div class="relative-selection-group-container">
      <p>Minutes:</p>
      <mat-button-toggle-group class="asc-toggle-group" name="timeTange" aria-label="Time Range">
        @for (range of rangeMinutes; track range) {
        <mat-button-toggle
          [ngClass]="selectedRelativeSeconds && selectedRelativeSeconds === range.seconds ? 'active' : 'inactive'"
          [value]="range"
          (change)="selectRange(range)"
        >
          {{ range.label }}
        </mat-button-toggle>
        }
      </mat-button-toggle-group>
      <p>Hours:</p>
      <mat-button-toggle-group class="asc-toggle-group" name="timeTange" aria-label="Time Range">
        @for (range of rangeHours; track range) {
        <mat-button-toggle
          [ngClass]="selectedRelativeSeconds && selectedRelativeSeconds === range.seconds ? 'active' : 'inactive'"
          [value]="range"
          (change)="selectRange(range)"
        >
          {{ range.label }}
        </mat-button-toggle>
        }
      </mat-button-toggle-group>
      <p>Days:</p>
      <mat-button-toggle-group class="asc-toggle-group" name="timeTange" aria-label="Time Range">
        @for (range of rangeDays; track range) {
        <mat-button-toggle
          [ngClass]="selectedRelativeSeconds && selectedRelativeSeconds === range.seconds ? 'active' : 'inactive'"
          [value]="range"
          (change)="selectRange(range)"
        >
          {{ range.label }}
        </mat-button-toggle>
        }
      </mat-button-toggle-group>
      <p>Weeks:</p>
      <mat-button-toggle-group class="asc-toggle-group" name="timeTange" aria-label="Time Range">
        @for (range of rangeWeeks; track range) {
        <mat-button-toggle
          [ngClass]="selectedRelativeSeconds && selectedRelativeSeconds === range.seconds ? 'active' : 'inactive'"
          [value]="range"
          (change)="selectRange(range)"
        >
          {{ range.label }}
        </mat-button-toggle>
        }
      </mat-button-toggle-group>
      <p>Custom:</p>
      <div class="custom-relative-wrapper">
        <app-asc-text-input class="custom-amount-input" [control]="customRelativeInputControl" inputType="number" placeholder="Amount"></app-asc-text-input>
        @if (units$ | async) {
        <app-asc-autocomplete class="custom-unit-input" [properties]="customConfig" [control]="customRelativeSelectorControl" [data]="units$" [clear]="false">
        </app-asc-autocomplete>
        }
      </div>
    </div>
  </div>
</ng-template>
