@if (label) {
<mat-label>{{ label }}</mat-label>
}
<mat-form-field appearance="outline" placeholder="test">
  @if (prefixIcon) {
  <mat-icon matPrefix>{{ prefixIcon }}</mat-icon>
  } @if (type === 'textarea') {
  <textarea rows="{{ rows }}" matInput [formControl]="control"></textarea>
  } @if (!autocomplete && !matDatePicker && type !== 'textarea') {
  <input
    [readonly]="useAsTrigger"
    [type]="inputType"
    matInput
    [formControl]="control"
    [placeholder]="placeholder"
    (keyup.enter)="onEnter()"
    [value]="valueTransformer(control.value)"
  />
  } @if (autocomplete) {
  <input
    [type]="inputType"
    matInput
    [formControl]="control"
    [placeholder]="placeholder"
    (keyup.enter)="onEnter()"
    [matAutocomplete]="autocomplete"
    [value]="valueTransformer(control.value)"
  />
  } @if (matDatePicker) {
  <input [type]="inputType" matInput [formControl]="control" [placeholder]="placeholder" (keyup.enter)="onEnter()" [matDatepicker]="matDatePicker" />
  } @if (matDatePicker) {
  <mat-datepicker-toggle matPrefix [for]="matDatePicker"></mat-datepicker-toggle>
  } @if (suffixIcon) {
  <mat-icon matSuffix>{{ suffixIcon }}</mat-icon>
  } @if (clear || matDatePicker) {
  <mat-icon matSuffix class="clear-icon-button" (click)="[$event.stopPropagation(), control.setValue(null), clearedInput()]">close</mat-icon>
  } @if (control.invalid) {
  <mat-error>
    {{ errorMessage }}
  </mat-error>
  }
  <ng-content select="[selectOptions]"></ng-content>
</mat-form-field>
