import { catchError, finalize, of } from 'rxjs';
import { DataSourceBaseImpl } from 'src/app/shared/interfaces/DataSourceBase';
import { LoggingService } from '../services/logging.service';
import { Parser } from './Parser';

export class ParserListDataSource extends DataSourceBaseImpl<Parser> {
  constructor(private loggingService: LoggingService) {
    super();
  }
  loadParsers(pageSize: number, pageIndex: number, sortCoulmn: string, sortDirection: string) {
    this.loadingSubject.next(true);
    this.errorSubject.next(null);
    this.loggingService
      .getParserList(pageSize, pageIndex, sortCoulmn, sortDirection)
      .pipe(
        catchError((err) => {
          this.errorSubject.next(err);
          return of();
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((resp) => {
        console.log(resp);

        if (resp.content) {
          this.dataSubject.next(resp.content);
        }
        this.totalSizeSubject.next(resp.totalSize);
      });
  }
}
