import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppStorageService } from 'src/app/shared/app-storage.service';
import { ConfigPageEvent } from 'src/app/shared/components/asc-configurable-table/asc-configurable-table.component';
import { ConfigureableTableColumn } from 'src/app/shared/interfaces/ConfigureableTableColumn';
import { StorageOperations } from 'src/app/shared/interfaces/StorageOperations';
import { AttachDeviceDialogData } from '../../interfaces/AttachDialogData';
import { Device } from '../../interfaces/Device';
import { DeviceListDataSource } from '../../interfaces/DeviceListDataSource';
import { DeviceApiService } from '../../services/device-api.service';
import { DeviceDetailsService } from '../../services/device-details.service';
import { AttachDeviceDialogComponent } from '../attach-device-dialog/attach-device-dialog.component';
import { ProvisionDeviceDialogComponent } from '../provision-device-dialog/provision-device-dialog.component';

@Component({
    selector: 'app-device-lifecycle',
    templateUrl: './device-lifecycle.component.html',
    styleUrls: ['./device-lifecycle.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', padding: '0 42px 0' })),
            state('expanded', style({ height: '*', padding: '0 42px 16px' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
        trigger('filterExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*', minHeight: '56px' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: false
})
export class DeviceLifecycleComponent implements OnInit, OnDestroy, StorageOperations {
  tableColumns: ConfigureableTableColumn<Device>[] = [
    {
      name: 'deviceType',
      header: 'Device Type',
      sortable: true,
    },
    {
      name: 'serialNumber',
      header: 'Serial Number',
      sortable: true,
    },
    {
      name: 'status',
      header: 'Status',
      sortable: true,
    },
    {
      name: 'lastConnected',
      header: 'Last Connected',
      sortable: true,
    },
    {
      name: 'trainingDevice',
      header: 'Training Device',
      sortable: false,
    },
    {
      name: 'options',
      header: 'Options',
      sortable: false,
    },
  ];

  subs$: Subscription = new Subscription();
  dataSource: DeviceListDataSource;
  searchTextControl = new FormControl<string>('', { nonNullable: true });
  searchSerialNumberControl = new FormControl<string>('', { nonNullable: true });
  sort: Sort;

  pageables: ConfigPageEvent;

  @ViewChild(MatPaginator) matPag: MatPaginator;
  @ViewChild(MatSort) matSort: MatSort;
  constructor(
    private deviceApiService: DeviceApiService,
    private router: Router,
    private store: AppStorageService,
    private dialog: MatDialog,
    private detailsService: DeviceDetailsService
  ) {
    this.getStorageData();
    this.subs$.add(
      this.detailsService.reloadDevice$.subscribe((resp) => {
        if (resp === 'RELOAD') {
          this.loadData();
        }
      })
    );

    this.dataSource = new DeviceListDataSource(this.deviceApiService);
    this.loadData();
    window.onbeforeunload = () => this.ngOnDestroy();
  }

  searchDevices() {
    this.pageables = {
      ...this.pageables,
      pageIndex: 0,
    };
    this.loadData();
  }

  getStorageData() {
    this.sort = {
      active: this.store.getValue<string>('devicelist_sort_column') || '',
      direction: this.store.getValue<SortDirection>('devicelist_sort_direction') || '',
    };
    this.pageables = {
      pageSize: this.store.getValue<number>('devicelist_pagination_pagesize') || 10,
      pageIndex: 0,
      pageSizeOptions: [10, 20, 50],
    };
    this.searchTextControl.setValue(this.store.getValue<string>('devicelist_filter_searchtext') || '');
    this.searchSerialNumberControl.setValue(this.store.getValue<string>('devicelist_filter_searchSerialNumber') || '');
  }

  sortChange(sort: Sort) {
    this.sort = sort;
    this.loadData();
  }

  ngOnInit(): void {
    window.onbeforeunload = () => this.saveStorageData();
  }

  loadData() {
    //TODO: add other query options
    this.dataSource.loadDevices(
      this.searchTextControl.value,
      this.pageables.pageSize,
      this.pageables.pageIndex,
      this.sort?.active && this.sort.direction ? this.sort.active : '',
      this.sort?.direction || '',
      undefined,
      undefined,
      this.searchSerialNumberControl.value
    );
  }

  paginatorListener(pagChange: PageEvent) {
    this.pageables = {
      ...this.pageables,
      pageIndex: pagChange.pageIndex,
      pageSize: pagChange.pageSize,
    };
    this.loadData();
  }

  openConnectionLog(element: Device): void {
    const queryParams: Params = {
      deviceId: element.id,
    };
    this.router.navigate(['connectionlog'], {
      queryParams,
    });
  }

  openUploadLog(element: Device): void {
    this.router.navigate(['uploadlog', element.deviceType, element.id]);
  }

  openLogParser(element: Device): void {
    this.router.navigate(['logparsermonitoring', element.deviceType, 'deviceid', element.id]);
  }

  saveStorageData() {
    this.store.setValue('devicelist_sort_column', this.sort.active ? this.sort.active : null);
    this.store.setValue('devicelist_sort_direction', this.sort.direction);
    this.store.setValue('devicelist_pagination_pagesize', this.pageables.pageSize);
    this.store.setValue('devicelist_filter_searchtext', this.searchTextControl.value || '', 'string', true);
    this.store.setValue('devicelist_filter_searchSerialNumber', this.searchSerialNumberControl.value || '', 'string', true);
  }

  toDetails(device: Device) {
    this.router.navigate(['/devicedetails', device.id]);
  }

  // device actions
  provisionDevice() {
    const dialogRef = this.dialog.open(ProvisionDeviceDialogComponent);
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp.status === 'SUCCESS') this.loadData();
    });
  }

  attachDevice(parent: Device) {
    const dialogConfig: MatDialogConfig<AttachDeviceDialogData> = {
      data: {
        child: parent,
      },
    };
    this.dialog.open(AttachDeviceDialogComponent, dialogConfig);
  }

  ngOnDestroy(): void {
    this.saveStorageData();
    this.subs$.unsubscribe();
  }
}
