<mat-autocomplete #autoGroup="matAutocomplete" [displayWith]="display.bind(this)" [panelWidth]="properties.panelWidth ? properties.panelWidth : ''">
  @for (option of filteredData$ | async; track option) {
  <mat-option [value]="option.data" (click)="selected($event, option)">
    <div (click)="selected($event, option)">
      @if (properties.multiple) {
      <mat-checkbox style="margin-right: 10px" (click)="$event.stopPropagation()" (change)="toggleSelection(option)" [checked]="option.selected"></mat-checkbox>
      }
      {{ properties.humanizeOption(option.data) }}
    </div>
  </mat-option>
  }
</mat-autocomplete>

<app-asc-text-input
  [errorMessage]="properties.errorMessage || 'Error'"
  [control]="filterControl"
  [autocomplete]="autoGroup"
  prefixIcon="arrow_drop_down"
  [clear]="clear"
  [placeholder]="properties.placeholder || 'Search'"
  (cleared)="selectionCleared()"
  [label]="properties.label"
  (keyupEnter)="setLatestFromInput()"
></app-asc-text-input>
