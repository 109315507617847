import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Device } from 'src/app/device/interfaces/Device';
import { AppStorageService } from 'src/app/shared/app-storage.service';
import { ConfigPageEvent } from 'src/app/shared/components/asc-configurable-table/asc-configurable-table.component';
import { ConfigureableTableColumn } from 'src/app/shared/interfaces/ConfigureableTableColumn';
import { StorageOperations } from 'src/app/shared/interfaces/StorageOperations';
import { DeviceFileUploadLogDataSource } from '../../interfaces/DeviceFileUploadLogDataSource';
import { FileUploadLog } from '../../interfaces/FileUploadLog';
import { LoggingService } from '../../services/logging.service';

@Component({
    selector: 'app-device-file-upload-log',
    templateUrl: './device-file-upload-log.component.html',
    styleUrls: ['./device-file-upload-log.component.scss'],
    standalone: false
})
export class DeviceFileUploadLogComponent implements StorageOperations, OnInit, OnDestroy {
  @Input() device: Device;
  sort: Sort;
  pageables: ConfigPageEvent;
  dataSource: DeviceFileUploadLogDataSource;
  tableColumns: ConfigureableTableColumn<FileUploadLog>[] = [
    {
      sortable: true,
      name: 'name',
      header: 'File name',
    },
    {
      sortable: true,
      name: 'totalFileSize',
      header: 'Size',
    },
    {
      sortable: true,
      name: 'uploadStart',
      header: 'Upload Start Time',
    },
    {
      sortable: true,
      name: 'uploadEnd',
      header: 'Upload End Time',
    },
    {
      sortable: true,
      name: 'uploadSpeed',
      header: 'Upload Speed (Mbps)',
    },
  ];
  constructor(private loggingApi: LoggingService, private store: AppStorageService) {
    this.dataSource = new DeviceFileUploadLogDataSource(this.loggingApi);
    this.getStorageData();

    window.onbeforeunload = () => this.ngOnDestroy();
  }
  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.dataSource.loadLogs(this.device.id, this.pageables.pageSize, this.pageables.pageIndex, this.sort.active, this.sort.direction);
  }

  pagChange(evt: PageEvent) {
    this.pageables = { ...this.pageables, pageIndex: evt.pageIndex, pageSize: evt.pageSize };
    this.loadData();
  }
  sortChange(evt: Sort) {
    this.sort = evt;
    this.loadData();
  }
  getStorageData(): void {
    this.sort = {
      active: this.store.getValue('device_fileuploadlog_sort_column') || '',
      direction: this.store.getValue('device_fileuploadlog_sort_direction') || '',
    };
    this.pageables = {
      pageSize: this.store.getValue('device_fileuploadlog_pagination_pagesize') || 10,
      pageIndex: 0,
      pageSizeOptions: [10, 20, 50],
    };
  }
  saveStorageData(): void {
    this.store.setValue('device_fileuploadlog_sort_column', this.sort.active);
    this.store.setValue('device_fileuploadlog_sort_direction', this.sort.direction);
    this.store.setValue('device_fileuploadlog_pagination_pagesize', this.pageables.pageSize);
  }
  ngOnDestroy(): void {
    this.saveStorageData();
  }
}
