import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
    selector: 'app-asc-text-input',
    templateUrl: './asc-text-input.component.html',
    styleUrls: ['./asc-text-input.component.scss'],
    standalone: false
})
export class AscTextInputComponent {
  @Input() name = 'inputName';
  @Input() control: FormControl = new FormControl('');
  @Input() type: 'text' | 'search' | 'textarea' = 'text';
  @Input() inputType: 'text' | 'number' = 'text';
  @Input() label?: string;
  @Input() icon?: string;
  @Input() clear = false;
  @Input() focus = false;
  @Input() placeholder = 'Search';
  @Input() multiple = false;
  @Input() rows = '1';

  @Input() useAsTrigger = false;

  @Input() prefixIcon?: string;
  @Input() suffixIcon?: string;

  @Input() matDatePicker: MatDatepicker<any>;

  @Input() formControlName: string;

  @Input() autocomplete: MatAutocomplete;

  @Input() errorMessage: string;
  @HostBinding('style.max-width')
  @Input()
  maxWidth = '320px';

  @Input() valueTransformer: (value: any) => string = (val) => val;

  @Output() keyupEnter = new EventEmitter();
  @Output() cleared = new EventEmitter();
  @Output() blured = new EventEmitter();

  @ViewChild('ascTextInput') elemRef: ElementRef;

  form: FormGroup = new FormGroup({});

  onEnter() {
    this.keyupEnter.emit(this.control.value);
  }

  clearedInput() {
    this.cleared.emit();
  }

  onBlur() {
    this.control.markAsTouched();
    this.blured.emit();
  }
}
